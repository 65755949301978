import {combineReducers} from 'redux';
import auth from './authReducer';
import signUp from './signUpReducer';
import loading from './loadingReducer';
import plan from './planReducer';
import {LOG_OUT} from '../actions/actionTypes';
import {connectRouter} from 'connected-react-router';

const allReducers =  (history) => combineReducers({
  signUp: signUp,
  auth: auth,
  loading: loading,
  plan: plan,
  router: connectRouter(history)
});

export default (history) => (state, action) => {
  if (action.type === LOG_OUT) {
    state = undefined;
  }
  return allReducers(history)(state, action);
};
