import {Plan, PlanTimePeriod} from '../lib/model/index';
import {
  FETCH_ALL_PLAN_TIME_PERIODS,
  LOADING,
  ADD_NEW_TIME_PERIOD_OPTION,
  MODIFY_TIME_PERIOD,
  DELETE_TIME_PERIOD
} from './actionTypes';
import moment from 'moment';
import _ from 'lodash' ;

export const addPlanDuration = (planId, userId,  range, dateType, documentId) => {
  return async () => {
    try {
      const startDate = moment(range.startDate).format('ll') ;
      const endDate = moment(range.endDate).format('ll');
      const rangeObj = await PlanTimePeriod.checkParticpantExistence(planId, userId,  startDate, endDate);
      const isRecordExist = await PlanTimePeriod.checkRecordExist(planId,  startDate, endDate);
      if (rangeObj) {
        _.remove(rangeObj.votes, (e) => {
          return e === userId;
        });
        await rangeObj.save();
        if (rangeObj.votes.length === 0) {
          await rangeObj.ref.delete();
        }
      } else {
        let dataObj;
        if (isRecordExist) {
          isRecordExist.votes.push(userId);
          await isRecordExist.save();
        } else {
          if (!_.isUndefined(documentId)) {
            dataObj = await PlanTimePeriod.getById(documentId);
            if (dataObj.votes.length !== 1) {
              _.remove(dataObj.votes, (e) => {
                return e === userId;
              });
              await dataObj.save();
              dataObj = await new PlanTimePeriod('');
            }
          } else {
            dataObj = await new PlanTimePeriod('');
          }
          dataObj.plan_id = planId;
          dataObj.user_id = userId;
          dataObj.date_type = dateType;
          dataObj.start_date = startDate;
          dataObj.end_date = endDate;
          dataObj.votes = [userId];
          await dataObj.save();
        }
      }
    } catch (error) {
      console.log('Error!!', error);
    }
  };
};

export const fetchPlanTimePeriods = (planId) => {
  return async (dispatch) => {
    try {
      dispatch({type: LOADING, payload: true});
      const timePeriodObj = await PlanTimePeriod.getPlanTimePeriodByPlanId(planId);
      let timePeriodObjects =  timePeriodObj && timePeriodObj.map((loc) => { return ({id: loc.id, ...loc.data});});
      timePeriodObjects && timePeriodObjects.sort((a, b) => {
        if (a.votes.length === b.votes.length) {
          const firstStartDate = moment(a.start_date);
          const secondStartDate = moment(b.start_date);
          const difference = firstStartDate.diff(secondStartDate);
          return difference < 0 ?  -1 : 1;
        }
        return b.votes.length - a.votes.length;
      });
      const planObj = await Plan.getByPlanId(planId);
      planObj.time_period = (timePeriodObjects && timePeriodObjects[0].start_date) || 'To be decided yet';
      await planObj.save();
      dispatch({
        type: FETCH_ALL_PLAN_TIME_PERIODS,
        payload: timePeriodObjects
      });
      dispatch({type: LOADING, payload: false});
    } catch (error) {
      dispatch({
        type: FETCH_ALL_PLAN_TIME_PERIODS,
        payload: []
      });
      dispatch({type: LOADING, payload: false});
      console.log('Error!!', error);
    }
  };
};

export const addNewTimePeriod = (newObj, id) => {
  newObj = {
    ...newObj,
    id
  };
  return async (dispatch) => {
    try {
      dispatch({
        type: ADD_NEW_TIME_PERIOD_OPTION,
        payload: newObj
      });
    } catch (error) {
      console.log('Error!!', error);
    }
  };
};

export const modifyTimePeriod = (newObj, id) => {
  newObj = {
    ...newObj,
    id
  };
  return async (dispatch) => {
    try {
      dispatch({
        type: MODIFY_TIME_PERIOD,
        payload: newObj
      });
    } catch (error) {
      console.log('Error!!', error);
    }
  };
};

export const deleteTimePeriod = (id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: DELETE_TIME_PERIOD,
        payload: id
      });
    } catch (error) {
      console.log('Error!!', error);
    }
  };
};
