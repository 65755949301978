let baseInviteLink = 'http://localhost:3000';
const environment = process.env.REACT_APP_ENVIRONMENT;

if (environment === 'development') {
  baseInviteLink = process.env.REACT_APP_STAGING_INVITE_LINK;
} else if (environment === 'production') {
  baseInviteLink = process.env.REACT_APP_PRODUCTION_INVITE_LINK;
}

export {baseInviteLink};
