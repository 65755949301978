import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import './index.css';

import store, { history } from './store/configureStore';
import App from './App';
import * as serviceWorker from './serviceWorker';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2094FA',
      light: '#cde4f7',
      dark: '#2094FA'
    },
    secondary: {
      main: '#fff'
    },
    text: {
      primary: '#000',
      secondary: '#fff'
    }
  }
});

ReactDOM.hydrate(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <MuiThemeProvider theme={theme}>
        <App />
      </MuiThemeProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'));
serviceWorker.unregister();
