Object.defineProperty(exports, '__esModule', {value: true});
var firebase_admin_1 = require('firebase');
var moment = require('moment');
var PlanTimePeriod = /** @class */ (function () {
  function PlanTimePeriod(snapshot) {
    if (snapshot instanceof firebase_admin_1.firestore.DocumentSnapshot) {
      this._id = snapshot.id;
      this._ref = snapshot.ref;
      this._data = snapshot.data();
    } else if (snapshot) {
      this._id = snapshot.id;
      this._data = snapshot;
      this._ref = PlanTimePeriod.colRef().doc(snapshot.id);
      if (snapshot.date_created) {
        this.date_created = moment(new firebase_admin_1.firestore.Timestamp(snapshot.date_created._seconds, snapshot.date_created._nanoseconds).toMillis());
      }
      if (snapshot.date_modified) {
        this.date_modified = moment(new firebase_admin_1.firestore.Timestamp(snapshot.date_modified._seconds, snapshot.date_modified._nanoseconds).toMillis());
      }
    } else {
      this._ref = PlanTimePeriod.colRef().doc();
      this._id = this._ref.id;
      this._data = {};
      this.is_delete = false;
      this.date_created = moment();
    }
  }
  PlanTimePeriod.colRef = function () {
    return firebase_admin_1.firestore().collection('plan_time_period');
  };
  PlanTimePeriod.prototype.save = function () {
    this.date_modified = moment();
    this._ref.set(this._data);
  };
  PlanTimePeriod.prototype.delete = function () {
    this.date_modified = moment();
    this._ref.delete();
  };
  Object.defineProperty(PlanTimePeriod.prototype, 'id', {
    get: function () {
      return this._id;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PlanTimePeriod.prototype, 'data', {
    get: function () {
      return this._data;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PlanTimePeriod.prototype, 'ref', {
    get: function () {
      return this._ref;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PlanTimePeriod.prototype, 'plan_id', {
    get: function () {
      return this._data.plan_id;
    },
    set: function (planId) {
      this._data.plan_id = planId;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PlanTimePeriod.prototype, 'plan_url', {
    get: function () {
      return this._data.plan_url;
    },
    set: function (planUrl) {
      this._data.plan_url = planUrl;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PlanTimePeriod.prototype, 'user_id', {
    get: function () {
      return this._data.user_id;
    },
    set: function (userId) {
      this._data.user_id = userId;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PlanTimePeriod.prototype, 'start_date', {
    get: function () {
      return this._data.start_date;
    },
    set: function (dateTime) {
      this._data.start_date = dateTime;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PlanTimePeriod.prototype, 'end_date', {
    get: function () {
      return this._data.end_date;
    },
    set: function (dateTime) {
      this._data.end_date = dateTime;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PlanTimePeriod.prototype, 'date_type', {
    get: function () {
      return this._data.date_type;
    },
    set: function (dateType) {
      this._data.date_type = dateType;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PlanTimePeriod.prototype, 'votes', {
    get: function () {
      return this._data.votes;
    },
    set: function (votes) {
      this._data.votes = votes;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PlanTimePeriod.prototype, 'is_delete', {
    get: function () {
      return this._data.is_delete;
    },
    set: function (isDelete) {
      this._data.is_delete = isDelete;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PlanTimePeriod.prototype, 'date_created', {
    get: function () {
      return moment(this._data.date_created.toMillis());
    },
    set: function (dateCreated) {
      this._data.date_created = firebase_admin_1.firestore.Timestamp.fromMillis(dateCreated.valueOf());
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PlanTimePeriod.prototype, 'date_modified', {
    get: function () {
      return moment(this._data.date_modified.toMillis());
    },
    set: function (dateModified) {
      this._data.date_modified = firebase_admin_1.firestore.Timestamp.fromMillis(dateModified.valueOf());
    },
    enumerable: true,
    configurable: true
  });
  PlanTimePeriod.getById = function (id) {
    return new Promise(function (resolve, reject) {
      try {
        PlanTimePeriod.colRef()
          .doc(id)
          .get()
          .then(function (document) {
            if (document.exists) {
              resolve(new PlanTimePeriod(document));
            } else {
              resolve();
            }
          })
          .catch(function (err) {
            reject(err);
          });
      } catch (ex) {
        reject(ex);
      }
    });
  };
  PlanTimePeriod.getPlanTimePeriodByUserAndPlanId = function (planId, userId) {
    return new Promise(function (resolve, reject) {
      try {
        PlanTimePeriod.colRef()
          .where('plan_id', '==', planId)
          .where('user_id', '==', userId)
          .get()
          .then(function (document) {
            if (document.empty) {
              resolve();
            } else {
              resolve(new PlanTimePeriod(document.docs[0]));
            }
          })
          .catch(function (err) {
            reject(err);
          });
      } catch (ex) {
        reject(ex);
      }
    });
  };
  PlanTimePeriod.getPlanTimePeriodByPlanId = function (planId) {
    return new Promise(function (resolve, reject) {
      try {
        PlanTimePeriod.colRef()
          .where('plan_id', '==', planId)
          .get()
          .then(function (document) {
            if (document.empty) {
              resolve();
            } else {
              resolve(document.docs.map(function (d) { return new PlanTimePeriod(d); }));
            }
          })
          .catch(function (err) {
            reject(err);
          });
      } catch (ex) {
        reject(ex);
      }
    });
  };
  PlanTimePeriod.checkRangeExistence = function (planId, userId, startDate, endDate) {
    return new Promise(function (resolve, reject) {
      try {
        PlanTimePeriod.colRef()
          .where('plan_id', '==', planId)
          .where('user_id', '==', userId)
          .where('start_date', '==', startDate)
          .where('end_date', '==', endDate)
          .get()
          .then(function (document) {
            if (document.empty) {
              resolve();
            } else {
              resolve(new PlanTimePeriod(document.docs[0]));
            }
          })
          .catch(function (err) {
            reject(err);
          });
      } catch (ex) {
        reject(ex);
      }
    });
  };
  PlanTimePeriod.checkParticpantExistence = function (planId, userId, startDate, endDate) {
    return new Promise(function (resolve, reject) {
      try {
        PlanTimePeriod.colRef()
          .where('plan_id', '==', planId)
          .where('votes', 'array-contains', userId)
          .where('start_date', '==', startDate)
          .where('end_date', '==', endDate)
          .get()
          .then(function (document) {
            if (document.empty) {
              resolve();
            } else {
              resolve(new PlanTimePeriod(document.docs[0]));
            }
          })
          .catch(function (err) {
            reject(err);
          });
      } catch (ex) {
        reject(ex);
      }
    });
  };
  PlanTimePeriod.checkRecordExist = function (planId, startDate, endDate) {
    return new Promise(function (resolve, reject) {
      try {
        PlanTimePeriod.colRef()
          .where('plan_id', '==', planId)
          .where('start_date', '==', startDate)
          .where('end_date', '==', endDate)
          .get()
          .then(function (document) {
            if (document.empty) {
              resolve();
            } else {
              resolve(new PlanTimePeriod(document.docs[0]));
            }
          })
          .catch(function (err) {
            reject(err);
          });
      } catch (ex) {
        reject(ex);
      }
    });
  };
  return PlanTimePeriod;
}());
exports.default = PlanTimePeriod;
