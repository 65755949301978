import {SIGN_UP_FAIL, SIGN_UP_LOADING} from '../actions/actionTypes';
import {INITIAL_STATE} from '../service/initialState';

export default (state = INITIAL_STATE.signUp, action) => {
  switch (action.type) {
    case SIGN_UP_FAIL:
      return {
        ...state,
        signup_error: action.signup_error
      };
    case SIGN_UP_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
};
