import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {Mixpanel} from '../analytics/Mixpanel';
import {Helmet} from 'react-helmet';
import {
  withStyles,
  Typography,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Avatar,
  CircularProgress,
  Grid,
  Tooltip
} from '@material-ui/core';
import {connect} from 'react-redux';
import moment from 'moment';
import firebase from 'firebase';

import {fetchAllPlans, storePlanId} from '../../actions/planAction';
import {loadingAction} from '../../actions/authAction';
import {Button} from '../../theme';
import './yourPlans.css';
import AddPlanDialog from '../createPlan/Dialogs/addPlanDialog';
import PlanDetailsDialog from './Dialogs/planDetailsDialog';
import {history} from '../../store/configureStore';

// Mixpanel.track('Successful signup');

const styles = ((theme) =>({
  card: {
    width: 'auto',
    margin: '20px 10px 0',
    boxShadow: '-10px -2px 20px 0px rgba(0, 0, 0, 0.14), 0px 6px 20px 6px rgba(0, 0, 0, 0.03)'
  },
  media: {
    height: 0,
    paddingTop: '56.25%'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  contentText: {
    background: '#ebebeb',
    padding: '15px 20px',
    cursor: 'pointer',
    height: '135px',
    overflow: 'auto'
  },
  textContent: {
    fontSize: '13px',
    color: '#484848',
    lineHeight: '22px'
  },
  footerBottom: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    minHeight: '60px'
  },
  multipleIcon: {
    display: 'flex',
    paddingLeft: '20px',
    '& div:nth-of-type(n)': {
      marginLeft: '-20px',
      border: '1px solid #333'
    }
  },
  loader: {
    position: 'fixed',
    top: '50%',
    left: '50%'
  },
  planDetails: {
    fontWeight: 'bold',
    color: '#444'
  }
}));

class YourPlans extends Component {

  constructor(props) {
    super(props);
    this.state = {
      openAddPlanDialog: false,
      openPlanDetails: false,
      plan: {}
    };
  }

  componentWillMount() {
    const {loadingAction} = this.props;
    loadingAction();
  }

  componentDidMount() {
    const {fetchAllPlans, auth} = this.props;
    try {
      firebase.firestore().collection('plan')
        .onSnapshot(async function (snapshot) {
          await fetchAllPlans(auth.userDetail);
        }, (err) => {
          console.log(`Encountered error: ${err}`);
        });
    } catch (err) {
      console.log('Error!', err);
    }
  }

  handleCreateNewPlan = () => {
    Mixpanel.track('Create new plan');
    this.setState({openAddPlanDialog: true});
  }

  handleCloseAddPlanDialog = () => {
    this.setState({openAddPlanDialog: false});
  }

  handleOpenPlanDetails = (planObj) => {
    this.setState({
      plan: planObj,
      openPlanDetails: true
    });
  }

  handleClosePlanDetails = () => {
    this.setState({openPlanDetails: false});
  }

  handleCardClick = (planId) => {
    history.push(`/plan/${planId}/what`);
  }

  render() {
    const {classes, plan, loading, auth, storePlanId} = this.props;
    const {openPlanDetails, openAddPlanDialog} = this.state;
    return (
      <div className='plan-wrapper'>
        <Helmet>
          <title>LetsPlan: Plan Your Next Meeting, Together.</title>
        </Helmet>
        <div className='yourPlans-header'>
          <Typography gutterBottom variant="h4" component="h1"> Your Plans</Typography>
        </div>
        <div className='btnWrapper'>
          <Button onClick={this.handleCreateNewPlan}> Create New Plan </Button>
          {openAddPlanDialog && <AddPlanDialog
            open={openAddPlanDialog}
            onClose={this.handleCloseAddPlanDialog}
            auth={auth}
            storePlanId={storePlanId}
          />}
        </div>
        {(loading) ? (
          <CircularProgress className={classes.loader} />
        ) : (
          <div>
            <div className='yourPlans-cardWrapper'>
              <Grid container>
                {
                  plan && plan.planList && plan.planList.length ? plan.planList.map((planObj, index)=> {
                    const name = planObj.plan_creator_name && planObj.plan_creator_name.match(/\b(\w)/g).join('');
                    return (
                      <Grid item xs={12} sm={4} md={3} lg={3} key={index}>
                        <Card className={classes.card} key={index}>
                          <CardHeader
                            avatar={
                              <Tooltip title={planObj.plan_creator_name || ''}>
                                <Avatar className='avtar-icon' aria-label="recipe">
                                  {name || 'No Username'}
                                </Avatar>
                              </Tooltip>
                            }
                            title={planObj.plan_title || 'No Title'}
                            titleTypographyProps={{variant: 'h6', color: 'primary'}}
                          />
                          <CardContent className={classes.contentText} onClick={()=>{this.handleCardClick(planObj.plan_unique_id);}}>
                            <Typography className={classes.textContent}>
                              <b>
                                {
                                  (planObj.time_period && moment(planObj.time_period).format('LL')) ||
                                  'To Be Decided'
                                }
                              </b>
                            </Typography>
                            <Typography className={classes.textContent}>Top Choice:<b>{planObj.venue || 'To Be Decided'}</b></Typography>
                            <Typography className={classes.textContent}>Confirmations:<b>{(planObj.participants && planObj.participants.length) || 'To be confirmed yet'}</b></Typography>
                          </CardContent>
                          <CardActions className={classes.footerBottom} disableSpacing>
                            <span className={classes.multipleIcon}>
                              {
                                planObj.participants && planObj.participants.map((participant, index)=>{
                                  const avatar = participant && participant.match(/\b(\w)/g).join('');
                                  return (
                                    <Tooltip title={participant || ''} key={index}>
                                      <Avatar className='avtar-icon' key={index}>{avatar}</Avatar>
                                    </Tooltip>
                                  );
                                })
                              }
                            </span>
                            <span className={classes.planDetails} onClick={()=> {this.handleOpenPlanDetails(planObj);}}>Plan Details</span>
                          </CardActions>
                        </Card>
                      </Grid>
                    );
                  }) :
                    (
                      <div className='noPlansDiv'>

                        <Typography gutterBottom variant="h4" component="h1">No Plans Yet? Let's Plan!</Typography>
                      </div>
                    )
                }
              </Grid>
            </div>
          </div>
        )}
        {
          openPlanDetails &&
          <PlanDetailsDialog
            open={openPlanDetails}
            onClose={this.handleClosePlanDetails}
            plan={this.state.plan}
          />
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    plan: state.plan,
    auth: state.auth.login_data,
    loading: state.loading.pageLoader
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators ({
    fetchAllPlans,
    storePlanId,
    loadingAction
  }, dispatch);
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(YourPlans));
