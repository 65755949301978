import React, {Component} from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
import {Button} from '../../../theme';
import {
  withStyles,
  Card,
  Divider, CircularProgress
} from '@material-ui/core';
import {ThumbDown, ThumbUp} from '@material-ui/icons';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {fetchPlanLocations, addPlanLocation, handleVotes} from '../../../actions/planAction';
import './where.css';
import firebase from 'firebase';
import AddInviteDialog from '../Dialogs/addInviteDialog';
import {Mixpanel} from '../../analytics/Mixpanel';

const styles = ((theme) =>({
  root: {
    width: '80%',
    margin: '0 auto'
  },
  loader: {
    position: 'fixed',
    top: '40%',
    left: '70%'
  },
  selected: {
    color: 'cornflowerblue'
  }
}));

class Where extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: '',
      openAddInviteDialog: false
    };
  }

  async componentDidMount() {
    const {fetchPlanLocations, planId}  = this.props;
    firebase.firestore().collection('plan_location')
      .onSnapshot(async function (snapshot) {
        await fetchPlanLocations(planId);
      });
  }

  handleChange = (address) => {
    this.setState({
      address: address
    });
  };

  handleSelect = async (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => console.log('Success', latLng))
      .catch((error) => console.error('Error', error));
    this.setState({
      address: address
    });
    const {auth: {login_data: {userDetail} = {}} = {}} = this.props;
    const {planId, addPlanLocation} = this.props;
    const response = await addPlanLocation(planId, address, userDetail.userEmail);
    if (response) {
      this.setState({address: ''});
      alert('Location already exists');
    }
  };

  handleUpVote = async (location) => {
    try {
      const {handleVotes, planId, auth: {login_data: {userDetail} = {}} = {}} = this.props;
      await handleVotes(planId, location, userDetail.userEmail, 'up');
    } catch (error) {
      console.log('Error!!', error);
    }
  }

  handleDownVote = async (location) => {
    try {
      const {handleVotes, planId, auth: {login_data: {userDetail} = {}} = {}} = this.props;
      await handleVotes(planId, location, userDetail.userEmail, 'down');
    } catch (error) {
      console.log('Error!!', error);
    }
  }

  handleCloseAddInviteDialog = () => {
    this.setState({openAddInviteDialog: false});
  }

  handleAddInviteDialog = () => {
    Mixpanel.track('Share plan');
    this.setState({openAddInviteDialog: true});
  }

  render() {
    const {openAddInviteDialog} = this.state;
    const {classes, plan, loading, auth: {login_data: {userDetail} = {}} = {}, planId} = this.props;
    return (
      <div>
        <Card className={classes.root}>
          <div className="where-main">
            <div className="where-first">
              <PlacesAutocomplete
                value={this.state.address}
                onChange={this.handleChange}
                onSelect={this.handleSelect}
              >
                {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                  <div className="location-input-search">
                    <input
                      {...getInputProps({
                        placeholder: 'Search & add choices for where to meet.',
                        className: 'location-search'
                      })}
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? 'suggestion-item--active'
                          : 'suggestion-item';
                        const style = suggestion.active
                          ? {backgroundColor: 'lightgrey', cursor: 'pointer', padding: '5px'}
                          : {backgroundColor: '#fff', cursor: 'pointer', padding: '5px'};
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </div>
            <div className="where-add">
              <h2>Location Choices</h2>
              <ul>
                {(loading) ? (
                  <CircularProgress className={classes.loader} />
                ) : (
                  plan && plan.locations && plan.locations.map((location, index)=>{
                    return (
                      <li key={index}>
                        <div className="add-list">
                          <div className="add-list-name">
                            <span>{location.location}</span>
                          </div>
                          <div className="add-list-vote">
                            <div>
                              <ThumbUp
                                className ={ location.up_votes.includes(userDetail.userEmail) ? classes.selected: ''}
                                onClick={() => {this.handleUpVote(location.location);}}/>
                              <span className="up-vote">{location.up_votes.length || '0'} votes</span>
                            </div>
                            <div className="list-down-vote">
                              <ThumbDown
                                className = { location.down_votes.includes(userDetail.userEmail) ? classes.selected: ''}
                                onClick={() => {this.handleDownVote(location.location);}}/>
                              <span className="down-vote">{location.down_votes.length || '0'} votes</span></div>
                          </div>
                        </div>
                        <Divider/>
                      </li>
                    );
                  }))
                }
              </ul>
            </div>
          </div>
          <Divider />
          <div className='who-button-chat'>
            <div className='btnWrapper'>
              <Button onClick={this.handleAddInviteDialog}> Add Invites</Button>
              {
                openAddInviteDialog &&
                <AddInviteDialog
                  open={openAddInviteDialog}
                  onClose={this.handleCloseAddInviteDialog}
                  planId={planId}
                />
              }
            </div>
          </div>
        </Card>
      </div>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    plan: state.plan,
    loading: state.loading.pageLoader,
    auth: state.auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators ({
    fetchPlanLocations,
    addPlanLocation,
    handleVotes
  }, dispatch);
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Where));
