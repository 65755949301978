let env = 'dev';

const environment = process.env.REACT_APP_ENVIRONMENT;

if (environment === 'production') {
  env = 'prod';
}

const configConstants = {
  dev: {
    firestore: {
      apiKey: 'AIzaSyB5MhkurLlsDq140elfyRvSKrGICbBEXz4',
      authDomain: 'letsplan-dev.firebaseapp.com',
      databaseURL: 'https://letsplan-dev.firebaseio.com',
      projectId: 'letsplan-dev',
      storageBucket: 'letsplan-dev.appspot.com',
      messagingSenderId: '263503090369',
      appId: '1:263503090369:web:12b2c269f50adef9733a40',
      measurementId: 'G-MR2BMYLNW0'
    }
  },
  prod: {
    firestore: {
      apiKey: 'AIzaSyCKzVqRik9lmawkAuHG6FupLNkhmTtMgks',
      authDomain: 'letsplan-app.firebaseapp.com',
      databaseURL: 'https://letsplan-app.firebaseio.com',
      projectId: 'letsplan-app',
      storageBucket: 'letsplan-app.appspot.com',
      messagingSenderId: '776030154480',
      appId: '1:776030154480:web:a26df347fe8319be4d9c35'
    }
  }
};

const config = configConstants[env];
export { config };
