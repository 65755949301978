import React, {PureComponent} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Dialog, IconButton, Typography, Avatar, Tooltip} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import {Close} from '@material-ui/icons';
import './planDetailsDialog.css';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    backgroundColor: '#2094FA',
    color: '#fff'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0),
    color: theme.palette.grey[500]
  },
  modalTitle: {
    '& h6': {
      fontSize: '20px',
      fontWeight: 600
    }
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const {children, classes, onClose} = props;
  return (
    <MuiDialogTitle className={`${classes.modalTitle} ${classes.root}`} disableTypography>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    textAlign: 'center'
  }
}))(MuiDialogContent);

class PlanDetailsDialog extends PureComponent {

  render() {
    const {open, onClose, plan} = this.props;
    return (
      <div>
        <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open} maxWidth={'sm'} fullWidth>
          <DialogTitle  id="customized-dialog-title" onClose={onClose}>
            Plan Details
          </DialogTitle>
          {
            plan &&  <DialogContent dividers>
              <div className="plan-details">
                <Tooltip title={plan.plan_creator_name || ''}>
                  <Avatar>{(plan.plan_creator_name && plan.plan_creator_name.match(/\b(\w)/g).join('')) || ' '}</Avatar>
                </Tooltip>
                <span className="ml-2">{plan.plan_creator_name || 'Anonymous Creator'}</span>
              </div>
              <div className="table-responsive">
                <table>
                  <tr>
                    <td>
                      <h4>Email</h4>
                    </td>
                    <td className="content-td">
                      <span>{plan.plan_creator_id || 'No Email Id' }</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h4>Title</h4>
                    </td>
                    <td className="content-td">
                      <span>{plan.plan_title || 'No Title'}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h4>Description</h4>
                    </td>
                    <td className="content-td">
                      <span>{plan.description || 'No Description'}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h4>Participants</h4>
                    </td>
                    <td className="block-ele-wrapper content-td">
                      {plan.participants ? plan.participants.map((participant, index) => {
                        return (
                          <span className="chip-text" key={index}>{participant}</span>
                        );
                      }) :
                        <span>No participants yet</span>
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h4>Plan Invite Link</h4>
                    </td>
                    <td className="content-td">
                      <span className="invite_link">{plan.plan_url || 'No Invite Link'}</span>
                    </td>
                  </tr>
                </table>
              </div>
            </DialogContent>
          }
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(PlanDetailsDialog);
