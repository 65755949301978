import {LOADING, SIGN_IN_LOADING, SIGN_UP_LOADING} from '../actions/actionTypes';
import {INITIAL_STATE} from '../service/initialState';

export default (state = INITIAL_STATE.loading, action) => {
  switch (action.type) {
    case LOADING: {
      return {
        ...state,
        pageLoader: action.payload
      };
    }
    case SIGN_IN_LOADING: {
      return {
        ...state,
        signInLoader: action.payload
      };
    }
    case SIGN_UP_LOADING: {
      return {
        ...state,
        signUpLoader: action.payload
      };
    }
    default:
      return state;
  }
};
