import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Avatar} from '@material-ui/core';
import {Plan} from '../../../../lib/model';
import './who.css';

class Who extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planObj: {}
    };
  }
  async componentDidMount() {
    const {planId} = this.props;
    const planObj = await Plan.getByPlanId(planId);
    this.setState({planObj});
  }

  render() {
    const {planObj} = this.state;
    return (
      <div className="who-mainMsg">
        <ul className="who-mainMsg-list">
          {
            planObj.participants && planObj.participants.map((obj, i)=>
              (
                <li key={i} className="who-mainMsg-name">
                  <Avatar>{obj.match(/\b(\w)/g).join('')}</Avatar>
                  <span>{obj}</span>
                </li>
              )
            )
          }
        </ul>
      </div>
    );
  }
}

export default connect(null, null)(Who);
