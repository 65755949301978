import { getItem } from "../utils/localStorageUtils";

export const INITIAL_STATE = {
  signUp: {
    signup_error: ''
  },
  auth: {
    login_data: {
      token: getItem('token'),
      userDetail: getItem('userDetail') && JSON.parse(getItem('userDetail')) || undefined
    },
    login_error: ''
  },
  loading: {
    signInLoader: false,
    signUpLoader: false,
    pageLoader: false
  },
  plan: {
    planList: [],
    planId: '',
    isInvite: false,
    locations: [],
    singleTimePeriods: [],
    multiTimePeriods: [],
    messages: []
  }
};
