import React, { Component } from 'react';
import {
  CircularProgress, Typography, OutlinedInput, withStyles
} from '@material-ui/core';
import { Button } from '../../theme';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import firebase from 'firebase';
import { LoginUser, loadingAction } from '../../actions/authAction';
import { validateEmail, validatePassword } from '../../utils/validation';
import { Plan } from '../../lib/model';
import { history } from '../../store/configureStore';
import './signin.css';
import { Mixpanel } from '../analytics/Mixpanel';
import { setItem } from '../../utils/localStorageUtils';

const styles = ((theme) => ({
  label: {
    color: '#494949',
    fontSize: theme.typography.htmlFontSize,
    fontWeight: theme.typography.fontWeightLight
  },
  loader: {
    color: '#fff'
  }
}));

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      errors: {},
      open: false
    };
  }

  handleChange = ({ target }) => {
    this.setState({
      [target.name]: target.value,
      errors: {}
    });
  };

  checkFieldValidation = () => {
    const { email = '', password = '' } = this.state;
    const emailValidation = validateEmail(email);
    const passwordValidation = validatePassword(password);
    if (emailValidation) {
      this.setState((state) => ({
        errors: { ...state.errors, email: { isError: true, errmsg: emailValidation } }
      }));
    }
    if (passwordValidation) {
      this.setState((state) => ({
        errors: { ...state.errors, password: { isError: true, errmsg: passwordValidation } }
      }));
    }
    if (!emailValidation && !passwordValidation) {
      return true;
    } else { return false; }
  }

  handleSignIn = async (e) => {
    const { email = '', password = '' } = this.state;
    const { LoginUser, plan } = this.props;
    try {
      if (this.checkFieldValidation()) {
        let LoginData = {
          email: email,
          password: password
        };
        const response = await LoginUser({ ...LoginData });
        debugger
        if (response.token) {
          await firebase.auth().signInWithCustomToken(response.token);
          Mixpanel.identify(response.userId);
          Mixpanel.track('Successful login');
          Mixpanel.people.set({
            $userName: response.userName,
            $userEmail: response.userEmail
          });
          setItem('token', response.token);
          const userDetail = {
            userId: response.userId,
            userName: response.userName,
            userEmail: response.userEmail
          };
          setItem('userDetail', JSON.stringify(userDetail));
          if (plan.isInvite) {
            const planObj = await Plan.getByPlanId(plan.planId);
            let participants = planObj && planObj.participants;
            let participantsEmail = planObj && planObj.participants_email;
            // To check whether user is participant of plan or not, if yes must not be added as participant
            if (participants !== undefined && !participants.includes(userDetail.userName)) {
              participants.push(userDetail.userName);
              participantsEmail.push(userDetail.userEmail);
            }
            planObj.participants = participants;
            planObj.participants_email = participantsEmail;
            await planObj.save();
            history.push(`/plan/${plan.planId}/what`);
          } else {
            history.push('/dashboard');
          }
        } else {
          alert(response);
          this.setState({
            email: '',
            password: ''
          });
          Mixpanel.track('Unsuccessful login');
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  handleSignUp = (e) => {
    e.preventDefault();
    history.push('/signup');
  };

  handleForgetPassword = () => {
    history.push('/forgotPassword');
  }

  render() {
    const { classes, loading } = this.props;
    const { email = '', password = '', errors = {} } = this.state;
    return (
      <div className='signin-wrapper'>
        <div className='signin-div'>
          <div className='signin-box-wrapper'>
            <div className='signin-form'>
              <Typography>Email</Typography>
              <OutlinedInput
                id="email"
                name="email"
                value={email}
                placeholder="Email"
                fullWidth={true}
                onChange={this.handleChange}
                error={errors.email && errors.email.isError}
              />
              {errors.email && <span className='errors'>{errors.email.errmsg}</span>}
            </div>
            <div className='signin-form'>
              <Typography>Password</Typography>
              <OutlinedInput
                id="password"
                name="password"
                type="password"
                placeholder="Password"
                value={password}
                fullWidth={true}
                onChange={this.handleChange}
                error={errors.password && errors.password.isError}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    this.handleSignIn(e);
                  }
                }}
              />
              {errors.password && <span className='errors'>{errors.password.errmsg}</span>}
            </div>
          </div>
          <div className='forgot-pwd'>
            <span onClick={this.handleForgetPassword}>Forgot Password</span>
          </div>
        </div>
        <Button
          variant="contained"
          onClick={this.handleSignIn}
        >
          {loading ? <CircularProgress className={classes.loader} size={25} /> : 'Login'}
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    login_data: state.auth.login_data,
    loading: state.loading.signInLoader,
    plan: state.plan
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    LoginUser,
    loadingAction
  }, dispatch);
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SignIn));
