import React, {Component} from 'react';
import {Typography} from '@material-ui/core';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import Header from '../header/header';
import {Button, Card, TextBox} from '../../theme';
import {ForgetPasswordAction} from '../../actions/forgotPasswordAction';
import './forgotPassword.css';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      showError: false,
      messageFromServer: '',
      showNullError: false
    };
  }

  handleChange = ({target}) => {
    this.setState({
      [target.name]: target.value
    });
  };

  handleSendResetMail = async (e) => {
    e.preventDefault();
    const {email} = this.state;
    let response;
    const {ForgetPasswordAction} = this.props;
    try {
      if (email === '') {
        this.setState({
          showError: false,
          messageFromServer: '',
          showNullError: true
        });
      } else {
        response = await ForgetPasswordAction(email);
        this.setState({
          showError: response.showError,
          messageFromServer: response.messageFromServer,
          showNullError: response.showNullError
        });
      }
    } catch (err) {
      console.log('error', err);
    }
  };

  render() {
    const {
      email, messageFromServer, showNullError, showError
    } = this.state;
    return (
      <div>
        <Header />
        <div className='forgot-password background-wrapper'>
          <Card>
            <div className='card-content'>
              <Typography gutterBottom variant="h4" component="h2" className='forgot-password-title card-title'>
                Forgot Password
              </Typography>
              <div>
                <div>
                  <TextBox
                    id="email"
                    name="email"
                    label="email"
                    value={email}
                    onChange={this.handleChange}
                    placeholder="Email Address" />
                </div>
                <div className='btnWrapper'>
                  <Button onClick={this.handleSendResetMail}
                    label = "Send Email"/>
                </div>
              </div>
              {showNullError && (
                <div>
                  <span style={{letterSpacing: 0, color: '#494949'}}>The email address cannot be null.</span>
                </div>
              )}
              {showError && (
                <div>
                  <span style={{letterSpacing: 0, color: '#494949'}}>
                  That email address isn&apos;t recognized. Please try again or
                  register for a new account.
                  </span>
                </div>
              )}
              {messageFromServer === 'recovery email sent' && (
                <div>
                  <h3 style={{letterSpacing: 0, color: '#494949'}}>Password Reset Email Successfully Sent!</h3>
                </div>
              )}
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators ({
    ForgetPasswordAction
  }, dispatch);
};

export default connect(null, mapDispatchToProps)(ForgotPassword);
