import React, {Component} from 'react';
import moment from 'moment';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import _ from 'lodash';
import {DateRangePicker} from '../../../src';
import Section from './Section';
import 'normalize.css';
import '../styles/global.css';
import '../styles/main.css';
import '../../../src/styles.scss';
import '../../../src/theme/default.scss';

import {addPlanDuration,
  fetchPlanTimePeriods,
  addNewTimePeriod,
  modifyTimePeriod,
  deleteTimePeriod} from '../../../../../actions/planTimeAction';
import {loadingAction} from '../../../../../actions/authAction';
import {PlanTimePeriod} from '../../../../../lib/model';
import firebase from 'firebase';
import {CircularProgress, Typography, withStyles, Card, Divider} from '@material-ui/core';
import {Button} from '../../../../../theme';
import AddInviteDialog from '../../../../createPlan/Dialogs/addInviteDialog';
import {Mixpanel} from '../../../../analytics/Mixpanel';

let myColor = '#3ecf8e';
let commonColor = '#fed14c';
let theirColor = '#63B8FF';

const styles = ((theme) => ({
  loader: {
    position: 'fixed',
    top: '50%',
    left: '70%'
  },
  root: {
    width: '80%',
    margin: '0 auto',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
}));

class SingleDay extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      openAddInviteDialog: false,
      count: 0,
      documentIds: [],
      commonCalendarRanges: [],
      myCalendarRanges: [{
        startDate: moment(),
        endDate: moment(),
        key: 'select',
        color: {myColor}
      }],
      dateRangePicker: {}
    };
  }

  async componentDidMount() {
    const {fetchPlanTimePeriods, planId} = this.props;
    fetchPlanTimePeriods(planId);
    const data = this.handleActivitySubscriptionWithCounter;
    this.snapshotMethod(data);

  }

  handleActivitySubscription = (snapshot, counter) => {
    const {
      addNewTimePeriod,
      modifyTimePeriod,
      deleteTimePeriod
    } = this.props;
    const initialLoad = counter === 1;
    snapshot.docChanges().forEach((change) => {
      if (change.type === 'added') {
        if (!initialLoad) {
          addNewTimePeriod(change.doc.data(), change.doc.id);
        }
      } else if (change.type==='modified') {
        modifyTimePeriod(change.doc.data(), change.doc.id);
      } else if (change.type === 'removed') {
        deleteTimePeriod(change.doc.id);
      }
    });
  }

  createFnCounter = (fn, invokeBeforeExecution) => {
    let count = 0;
    return (args) => {
      count = count + 1;
      if (count <= invokeBeforeExecution) {
        return true;
      } else {
        return fn(args, count);
      }
    };
  }

  handleActivitySubscriptionWithCounter = this.createFnCounter(this.handleActivitySubscription, 0);

  snapshotMethod = (data) => {
    this.unsubscribe = firebase.firestore().collection('plan_time_period')
      .where('plan_id', '==', this.props.planId)
      .onSnapshot(data);
  };

  componentWillUnmount() {
    this.unsubscribe();
  }

  componentWillReceiveProps(nextProps) {
    let {documentIds, commonCalendarRanges, myCalendarRanges} = this.state;
    const {auth: {login_data: {userDetail: {userEmail} = {}} = {}} = {}} = this.props;

    if (!_.isEqual(nextProps.plan.singleTimePeriods, commonCalendarRanges)) {
      const singleTimePeriods = nextProps.plan.singleTimePeriods;
      if (!_.isEmpty(singleTimePeriods)) {
        myCalendarRanges = [];
        commonCalendarRanges = [];
        documentIds = [];
        singleTimePeriods && singleTimePeriods.forEach((timePeriod) => {
          const agreed = timePeriod && timePeriod.votes.includes(userEmail);
          const rangeObj = {
            startDate: moment(timePeriod.start_date),
            endDate: moment(timePeriod.end_date),
            key: timePeriod.user_id,
            count: timePeriod.votes.length,
            agreed: agreed,
            color: {myColor},
            participants: timePeriod.votes
          };
          if (timePeriod.votes.includes(userEmail) && timePeriod.votes.length > 1) {
            rangeObj.color = commonColor;
          }
          if (_.isEqual(timePeriod.user_id, userEmail) || timePeriod.votes.includes(userEmail)) {
            myCalendarRanges.push(rangeObj);
            documentIds.push(timePeriod.id);
          } else {
            rangeObj.color = theirColor;
          }
          commonCalendarRanges.push(rangeObj);
        });
        if (myCalendarRanges.length === 0) {
          myCalendarRanges = [{
            startDate: moment(),
            endDate: moment(),
            key: 'select',
            color: {myColor}
          }];
        }
        this.setState({
          documentIds,
          myCalendarRanges,
          commonCalendarRanges
        });
      } else {
        myCalendarRanges = [{
          startDate: moment(),
          endDate: moment(),
          key: 'select',
          color: {myColor}
        }];
        commonCalendarRanges = [];
        this.setState({myCalendarRanges, commonCalendarRanges});
      }
    }
  }

  handleRangeChange = (which, payload) => {
    const maxNumberOfSelections = 30;
    const {documentIds, myCalendarRanges} = this.state;
    let {count} = this.state;
    if (documentIds.length < maxNumberOfSelections) {
      count = documentIds.length;
    }
    const {addPlanDuration, planId, auth: {login_data: {userDetail: {userEmail} = {}} = {}} = {}} = this.props;
    const data = payload['select'] !== undefined ?  {...payload.select} : {...payload[userEmail]};
    const endDate = moment(data.endDate);
    const startDate = moment(data.startDate);
    const offset = endDate.diff(startDate);
    if (offset === 0) {
      if (count >= maxNumberOfSelections) {
        count =  0;
      }
      let a = myCalendarRanges.slice(); //creates the clone of the array
      a[count] = data;
      this.setState({
        [which]: {...payload},
        myCalendarRanges: a
      }, async () => {
        const {dateRangePicker} = this.state;
        const range = dateRangePicker['select'] !== undefined ?  dateRangePicker.select : dateRangePicker[userEmail];
        try {
          await addPlanDuration(planId, userEmail, range, 'single', documentIds[count]);
        } catch (ex) {
          console.log('error', ex);
        }
      });
      this.setState({count: count + 1});
    }
  }

  handleAgreeVote = async (range) => {
    const {auth: {login_data: {userDetail: {userEmail} = {}} = {}} = {}, planId} = this.props;
    const startDate =  moment(range.startDate).format('ll');
    const endDate =  moment(range.endDate).format('ll');
    const rangeObj = await PlanTimePeriod.checkRecordExist(planId, startDate, endDate);
    if (rangeObj && rangeObj.votes && rangeObj.votes.includes(userEmail)) {
      _.remove(rangeObj.votes, (e) => {
        return e === userEmail;
      });
      await rangeObj.save();
      if (rangeObj.votes.length === 0) {
        await rangeObj.ref.delete();
      }
    } else {
      rangeObj.votes && rangeObj.votes.push(userEmail);
      await rangeObj.save();
    }
  }

  handleCloseAddInviteDialog = () => {
    this.setState({openAddInviteDialog: false});
  }

  handleAddInviteDialog = () => {
    Mixpanel.track('Share plan');
    this.setState({openAddInviteDialog: true});
  }

  render() {
    const {myCalendarRanges, commonCalendarRanges, openAddInviteDialog} = this.state;
    const {loading, classes, planId} = this.props;
    return (
      <Card className={classes.root}>
        <div className="display-common-color">
          <div className='green-color'>
            <div className="green-color-display"></div>
            <span> My Dates</span>
          </div>
          <div className='blue-color'>
            <div className="blue-color-display"></div>
            <span> Other Planners'</span>
          </div>
          <div className="yellow-color">
            <div className="yellow-color-display"></div>
            <span> Common</span>
          </div>
        </div>
        <Divider/>
        <main className={'Main'}>
          <Section title="My Calendar">
            <div>
            </div>
            <div>
              <Typography className="calender-info">Select your available choice in my calendar</Typography>
              {myCalendarRanges && myCalendarRanges.length > 0 && <DateRangePicker
                onChange={this.handleRangeChange.bind(this, 'dateRangePicker')}
                moveRangeOnFirstSelection={false}
                className={'PreviewArea'}
                months={1}
                ranges={myCalendarRanges}
                direction="horizontal"
                minDate={new Date()}
                dragSelectionEnabled={false}
                showSelectionPreview={false}
                showPreview={false}
              />}
            </div>
          </Section>
          <Section title="Common Calendar">
            <div>
            </div>
            {!loading ? commonCalendarRanges && commonCalendarRanges.length > 0 ?
              <>
                <Typography className="calender-info">Common calendar shows dates selected by participants of plan</Typography>
                <DateRangePicker
                  ranges={commonCalendarRanges}
                  months={1}
                  direction='horizontal'
                  onChange={()=>{}}
                  dragSelectionEnabled={false}
                  showPreview={false}
                  className={'PreviewArea1'}
                  type="common"
                  handleAgreeVote={this.handleAgreeVote}
                />
              </>
              :
              <Typography>Select the dates in <b> My Calendar</b> and let people know your available dates in common calendar</Typography>
              : <CircularProgress className={classes.loader} size={40}/>}
          </Section>
        </main>
        <Divider/>
        <div className='who-button-chat'>
          <div className='btnWrapper'>
            <Button onClick={this.handleAddInviteDialog}> Invite More Planners</Button>
            {
              openAddInviteDialog &&
                <AddInviteDialog
                  open={openAddInviteDialog}
                  onClose={this.handleCloseAddInviteDialog}
                  planId={planId}
                />
            }
          </div>
        </div>

      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    plan: state.plan,
    loading: state.loading.pageLoader
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators ({
    addPlanDuration,
    fetchPlanTimePeriods,
    addNewTimePeriod,
    modifyTimePeriod,
    deleteTimePeriod,
    loadingAction
  }, dispatch);
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SingleDay));
