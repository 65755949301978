import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  AppBar, CircularProgress, Tab, Tabs, withStyles, Typography
} from '@material-ui/core';

import What from './what/what';
import When from './when/when';
import Where from './where/where';
import {history} from '../../store/configureStore';
import {storePlanId, setInviteFlag} from '../../actions/planAction';
import {Plan} from '../../lib/model';

import './createPlan.css';
import {bindActionCreators} from 'redux';

const styles = ((theme) => ({
  root: {
    width: '80%',
    margin: '0 auto'
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none'
  },
  indicator: {
    backgroundColor: 'transparent'
  },
  tab: {
    opacity: 1,
    textTransform: 'capitalize',
    fontSize: '100%',
    '&:hover': {
      color: 'white'
    },
    '&.Mui-selected': {
      color: '#2094FA',
      backgroundColor: '#fff'
    }
  },
  header1: {
    color: '#fff',
    marginLeft: '0%'
  },
  loader: {
    position: 'fixed',
    top: '45%',
    left: '50%'
  }
}));

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

class CreatePlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planId: '',
      isPlanExist: false,
      loading: false
    };
  }

  async componentDidMount() {
    const {storePlanId, match: {params: {id} = {}} = {}} = this.props;
    this.setState({loading: true});
    const isPlan = await Plan.getByPlanId(id);
    if (isPlan) {
      storePlanId(id);
      this.setState({planId: id, isPlanExist: true, loading: false});
    } else {
      this.setState({loading: false});
    }
  }

  handleWhatClick = () => {
    history.push(`/plan/${this.state.planId}/what?tab=chat`);
  };

  handleWhenClick = () => {
    history.push(`/plan/${this.state.planId}/when`);
  };

  handleWhereClick = () => {
    history.push(`/plan/${this.state.planId}/where`);
  };

  renderChild = () => {
    const {match: {params: {comp, id} = {}} = {}} = this.props;
    switch (comp) {
      case 'what':
        return <What comp={comp} planId={id} />;
      case 'when':
        return <When planId={id} />;
      case 'where':
        return <Where planId={id} />;
      default:
        return <What planId={id} />;
    }
  }

  render() {
    const {classes, match: {params: {comp} = {}} = {}} = this.props;
    const {loading, isPlanExist} = this.state;
    const tabsValueArr = {'what': 0, 'where': 1, 'when': 2};
    return (
      !loading ? (isPlanExist ? (
        <div className="tabs">

          <div className="header-main">
            <AppBar position="static" className={classes.header}>
              <Tabs
                variant="fullWidth"
                classes={{indicator: classes.indicator}}
                className={classes.header1}
                value={tabsValueArr[comp]}
              >
                <LinkTab label="Summary" onClick={this.handleWhatClick} className={classes.tab} />
                <LinkTab label="Location" onClick={this.handleWhereClick} className={classes.tab} />
                <LinkTab label="Calendar" onClick={this.handleWhenClick} className={classes.tab} />
              </Tabs>
            </AppBar>
          </div>
          <div>
            { this.renderChild()}
          </div>
        </div>) :
        (
          <div className="no-such-plan">No such Plan Exists</div>
        ))
        :
        <CircularProgress className={classes.loader} size={25}/>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    storePlanId,
    setInviteFlag
  }, dispatch);
};

export default withStyles(styles)(connect(null, mapDispatchToProps)(CreatePlan));
