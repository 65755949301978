import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {
  withStyles,
  IconButton,
  Divider,
  Card,
  TextField,
  CardActions,
  Avatar, Tooltip
} from '@material-ui/core';
import {connect} from 'react-redux';
import {Send} from '@material-ui/icons';

import {fetchMessages, addMessage, addNewMessage} from '../../../../actions/chatAction';
import './chat.css';
import firebase from 'firebase';
import moment from 'moment';

const styles = ((theme) => ({
  card: {
    width: '100%',
    position: 'relative'
  },
  cardHeading: {
    backgroundColor: '#f1f1f1',
    color: '#2094FA'
  },
  footer: {
    display: 'block',
    backgroundColor: '#f1f1f1'
  },
  msgText: {
    display: 'flex'
  },
  message: {
    width: '100%',
    '& div.MuiOutlinedInput-multiline': {
      padding: '14.5px 14px',
      backgroundColor: 'white'
    }
  },
  msgCount: {
    alignSelf: 'flex-end',
    marginLeft: 10,
    marginBottom: 5,
    marginRight: 10,
    width: 40
  },
  divider: {
    border: '0.5px solid #eeeeee'
  },
  fab: {
    margin: theme.spacing(1)
  }
}));

class Chat extends Component {

  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      messageData: {
        message: ''
      },
      showReplyBox: {}
    };
  }

  async componentDidMount() {
    try {
      const {fetchMessages, planId} = this.props;
      await fetchMessages(planId);
      const data = this.handleActivitySubscriptionWithCounter;
      this.snapshotMethod(data);
    } catch (ex) {
      console.log(ex);
    }
  }

  handleActivitySubscription = (snapshot, counter) => {
    const {
      addNewMessage
    } = this.props;
    const initialLoad = counter === 1;
    snapshot.docChanges().forEach((change) => {
      if (change.type === 'added') {
        if (!initialLoad) {
          addNewMessage(change.doc.data(), change.doc.id);
        }
      }
    });
  }

  createFnCounter = (fn, invokeBeforeExecution) => {
    let count = 0;
    return (args) => {
      count = count + 1;
      if (count <= invokeBeforeExecution) {
        return true;
      } else {
        return fn(args, count);
      }
    };
  }

  handleActivitySubscriptionWithCounter = this.createFnCounter(this.handleActivitySubscription, 0);

  snapshotMethod = (data) => {
    this.unsubscribe = firebase.firestore().collection('chat')
      .where('plan_id', '==', this.props.planId)
      .onSnapshot(data);
  };

  componentWillUnmount() {
    this.unsubscribe();
  }

  componentDidUpdate() {
    const elem = this.mainMsg;
    elem.scrollTop = elem.scrollHeight;
  }

  handleChange = (event) => {
    const {target} = event;
    this.setState((state) => ({
      messageData: {...state.messageData, [target.name]: target.value}
    }));
  };

  send = async (e) => {
    e.preventDefault();
    try {
      const {addMessage, planId, auth: {userDetail} = {}} = this.props;
      const {messageData: {message} = {}} = this.state;
      await addMessage(planId, userDetail.userName, userDetail.userEmail, message);
      this.setState((state) => ({
        messageData: {
          ...state.messageData,
          message: ''
        }
      }));
    } catch (ex) {
      console.log(ex);
    }
  };

  render() {
    const {
      messageData
    } = this.state;
    const {classes, plan, auth: {userDetail: {userEmail} = {}} = {}} = this.props;
    let prevDate, currentDate, head;
    prevDate = plan.messages[0] && moment(plan.messages[0].date).format('ll');
    return (
      <div>
        <Card className={classes.card}>
          <div ref={(el) => { this.mainMsg = el; }} className='mainMsg'>
            {
              plan.messages && plan.messages.length > 0 && plan.messages.map((message, i) => {
                currentDate = moment(message.date).format('ll');
                if (prevDate !== currentDate) {
                  head = <span>{moment(message.date).format('LL')}</span>;
                  prevDate = currentDate;
                } else if (i===0) {
                  head =  <span>{prevDate}</span>;
                } else {
                  head = null;
                }
                return (<div>
                  <div className="date-wrapper"> {head}</div>
                  <div key={i}>
                    <div className={
                      userEmail !== message.planner_email ?
                        'receiverMain' :
                        'senderMain'
                    }>
                      <div
                        key={i}
                        className={
                          userEmail !== message.planner_email ?
                            'message-box receiver' :
                            'message-box sender'
                        }>
                        <div className='messageBoxContent'>
                          <span className='messageBoxContent'>{message.message}</span>
                        </div>
                        <div className='messageBoxHeaderTime'>
                          <span className='messageBoxHeaderTime'>{moment(message.date).format('HH:mm a')}</span>
                        </div>
                      </div>
                      <div className="avatar">
                        <div className='messageBoxHeader'>
                          <span className='messageBoxHeader'>{message.planner_name}</span>
                        </div>
                        <Tooltip title={message.planner_name || ''}>
                          <Avatar className='profileImg'>{message.planner_name.match(/\b(\w)/g).join('')}</Avatar>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>);
              })}
          </div>
          <Divider className={classes.divider}/>
          <CardActions disableSpacing className={classes.footer}>
            <div className={classes.msgText}>
              <TextField
                id="outlined-multiline-flexible"
                multiline
                rowsMax="4"
                rows="1"
                placeholder="Write a message"
                value={messageData.message}
                onChange={this.handleChange}
                className={classes.message}
                variant="outlined"
                name="message"
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    this.send(e);
                  }
                }}
              />
              <IconButton className={classes.button} aria-label="send" onClick={this.send}>
                <Send/>
              </IconButton>
            </div>
          </CardActions>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.login_data,
    plan: state.plan
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    fetchMessages,
    addMessage,
    addNewMessage
  }, dispatch);
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Chat));
