import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { history } from '../../store/configureStore';
import DashHeader from '../dashHeader/dashHeader';
import './privateRoute.css';
import { clearStorage, getItem } from '../../utils/localStorageUtils';


const PrivateRoute = (props) => {
  const token = getItem('token')
  let access = false;
  let decoded;
  try {
    if (token === undefined) {
      access = false;
    } else {
      decoded = jwt_decode(token);
    }
  } catch (err) {
    console.log(err);
  }
  let currentTime = Math.floor(Date.now() / 1000);
  if (!decoded || !decoded.exp) {
    clearStorage();
    access = false;
  } else {
    if (decoded.exp < currentTime) {
      access = false;
      clearStorage();
    } else {
      access = true;
    }
  }
  if (access) {
    return (
      <>
        <DashHeader history={history} />
        <div className="private-route">
          <Route {...props} />
        </div>
      </>
    );
  } else {
    return (<Redirect to='/' />);
  }
};
export default PrivateRoute;
