Object.defineProperty(exports, '__esModule', {value: true});
var firebase_admin_1 = require('firebase');
var moment = require('moment');
var User = /** @class */ (function () {
  function User(snapshot) {
    if (snapshot instanceof firebase_admin_1.firestore.DocumentSnapshot) {
      this._id = snapshot.id;
      this._ref = snapshot.ref;
      this._data = snapshot.data();
    } else if (snapshot) {
      this._id = snapshot.id;
      this._data = snapshot;
      this._ref = User.colRef().doc(snapshot.id);
      if (snapshot.date_created) {
        this.date_created = moment(new firebase_admin_1.firestore.Timestamp(snapshot.date_created._seconds, snapshot.date_created._nanoseconds).toMillis());
      }
      if (snapshot.date_modified) {
        this.date_modified = moment(new firebase_admin_1.firestore.Timestamp(snapshot.date_modified._seconds, snapshot.date_modified._nanoseconds).toMillis());
      }
    } else {
      this._ref = User.colRef().doc();
      this._id = this._ref.id;
      this._data = {};
      this.is_delete = false;
      this.date_created = moment();
    }
  }
  User.colRef = function () {
    return firebase_admin_1.firestore().collection('user');
  };
  User.prototype.save = function () {
    this.date_modified = moment();
    this._ref.set(this._data);
  };
  Object.defineProperty(User.prototype, 'id', {
    get: function () {
      return this._id;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(User.prototype, 'data', {
    get: function () {
      return this._data;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(User.prototype, 'ref', {
    get: function () {
      return this._ref;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(User.prototype, 'first_name', {
    get: function () {
      return this._data.first_name;
    },
    set: function (firstName) {
      this._data.first_name = firstName;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(User.prototype, 'last_name', {
    get: function () {
      return this._data.last_name;
    },
    set: function (lastName) {
      this._data.last_name = lastName;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(User.prototype, 'email', {
    get: function () {
      return this._data.email;
    },
    set: function (email) {
      this._data.email = email;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(User.prototype, 'salt', {
    get: function () {
      return this._data.salt;
    },
    set: function (salt) {
      this._data.salt = salt;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(User.prototype, 'mobile_number', {
    get: function () {
      return this._data.mobile_number;
    },
    set: function (mobileNumber) {
      this._data.mobile_number = mobileNumber;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(User.prototype, 'is_delete', {
    get: function () {
      return this._data.is_delete;
    },
    set: function (isDelete) {
      this._data.is_delete = isDelete;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(User.prototype, 'date_created', {
    get: function () {
      return moment(this._data.date_created.toMillis());
    },
    set: function (dateCreated) {
      this._data.date_created = firebase_admin_1.firestore.Timestamp.fromMillis(dateCreated.valueOf());
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(User.prototype, 'date_modified', {
    get: function () {
      return moment(this._data.date_modified.toMillis());
    },
    set: function (dateModified) {
      this._data.date_modified = firebase_admin_1.firestore.Timestamp.fromMillis(dateModified.valueOf());
    },
    enumerable: true,
    configurable: true
  });
  User.getById = function (id) {
    return new Promise(function (resolve, reject) {
      try {
        User.colRef()
          .doc(id)
          .get()
          .then(function (document) {
            if (document.exists) {
              resolve(new User(document));
            } else {
              resolve();
            }
          })
          .catch(function (err) {
            reject(err);
          });
      } catch (ex) {
        reject(ex);
      }
    });
  };
  return User;
}());
exports.default = User;
