import * as _ from 'lodash';

import { axiosInstance } from '../config/axiosConfig';
import { LOGIN_SUCCESS, LOGIN_FAIL, LOG_OUT, LOADING, SIGN_IN_LOADING } from './actionTypes';

export const LoginUser = (credentials) => {
  return async (dispatch) => {
    try {
      debugger
      dispatch({ type: SIGN_IN_LOADING, payload: true });
      const response = await axiosInstance({
        method: 'post',
        url: '/authenticate',
        data: { ...credentials },
        validateStatus: () => {
          return true;
        }
      });
      if (response.status === 200) {
        dispatch({
          type: LOGIN_SUCCESS,
          data: response.data
        });
        return response.data;
      } else if (response.status === 404) {
        dispatch({ type: SIGN_IN_LOADING, payload: false });
        dispatch({
          type: LOGIN_FAIL,
          login_error: response && _.isArray(response.data) ? response.data[0] : response.data
        });
        return _.isArray(response.data) ? response.data[0] : response.data;
      } else if (response.status === 500) {
        dispatch({ type: SIGN_IN_LOADING, payload: false });
        dispatch({
          type: LOGIN_FAIL,
          login_error: response && _.isArray(response.data) ? response.data[0] : response.data
        });
        return _.isArray(response.data) ? response.data[0] : response.data;
      }
    } catch (error) {
      dispatch({ type: SIGN_IN_LOADING, payload: false });
      dispatch({
        type: LOGIN_FAIL,
        login_error: error.response && _.isArray(error.response.data) ? error.response.data[0] : error.response.data
      });
      return _.isArray(error.response.data) ? error.response.data[0] : error.response.data;
    }
  };
};

export const LogOut = () => {
  return (dispatch) => {
    dispatch({
      type: LOG_OUT
    });
  };
};

export const loadingAction = (status) => {
  return (dispatch) => {
    dispatch({
      type: LOADING,
      payload: status
    });
  };
};
