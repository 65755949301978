import * as _ from 'lodash';

import {axiosInstance} from '../config/axiosConfig';
import {SIGN_UP_FAIL, SIGN_UP_LOADING, LOGIN_SUCCESS} from './actionTypes';

export const SignUpUser = (userData) => {
  return async (dispatch) => {
    try {
      dispatch({type: SIGN_UP_LOADING, payload: true});
      const response =  await axiosInstance({
        method: 'post',
        url: '/signup',
        data: {...userData},
        validateStatus: () => {
          return true;
        }
      });
      if (response.status === 200) {
        dispatch({type: SIGN_UP_LOADING, payload: false});
        dispatch({
          type: LOGIN_SUCCESS,
          data: response.data
        });
        return response.data;
      } else if (response.status === 500) {
        dispatch({type: SIGN_UP_LOADING, payload: false});
        dispatch({
          type: SIGN_UP_FAIL,
          signup_error: response &&  _.isArray(response.data) ? response.data[0] : response.data
        });
        return _.isArray(response.data) ? response.data[0] : response.data;
      }
    } catch (error) {
      dispatch({type: SIGN_UP_LOADING, payload: false});
      dispatch({
        type: SIGN_UP_FAIL,
        signup_error: error.response && _.isArray(error.response.data) ? error.response.data[0] : error.response.data
      });
      return _.isArray(error.response.data) ? error.response.data[0] : error.response.data;
    }
  };
};

export default SignUpUser;
