import React from 'react';
import {
  Button
} from '@material-ui/core';
import clsx from 'clsx';
import {withStyles} from '@material-ui/core/styles';

const styles = ((theme) =>({
  root: {
    ...theme.typography.button,
    borderRadius: 0,
    boxSizing: 'border-box',
    color: theme.palette.text.primary,
    minWidth: 100,
    padding: '2px',
    textTransform: 'capitalize',
    boxShadow: '1px 1px 10px 1px silver '
  },
  btnContained: {
    boxShadow: '1px 1px 10px 1px silver',
    color: theme.palette.text.secondary
  },
  btnOutlined: {
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.secondary,
      borderColor: theme.palette.primary.main
    }
  },
  buttonLabel: {
    fontSize: 16,
    fontWeight: 400
  },
  smallButtonLabel: {
    fontSize: 15
  }
}));

const MainButton = (props) => {
  const {classes, size = 'medium', variant = 'contained'} = props;
  const outlined = variant === 'outlined';
  const contained = variant === 'contained';
  const className = clsx(
    classes.root,
    {
      [classes.btnOutlined]: outlined,
      [classes.btnContained]: contained
    }
  );
  const labelClassName = clsx(
    classes.buttonLabel,
    {
      [classes.smallButtonLabel]: size === 'small'
    }
  );
  return (
    <Button
      variant={props.variant}
      color="primary"
      className={props.className || className}
      onClick={props.onClick}
      classes={{label: labelClassName}}
    >
      {props.children || props.label}
    </Button>
  );
};

export default withStyles(styles)(MainButton);
