import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import rootReducer from '../reducers';
import { INITIAL_STATE } from '../service/initialState';

export const history = (typeof window === 'object') ? createBrowserHistory() : {}

const composeEnhancer =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;

const enhancer = composeEnhancer(
  applyMiddleware(thunk, routerMiddleware(history))
);

export default createStore(rootReducer(history), INITIAL_STATE, enhancer);
