export function getItem(key) {
  if (typeof window !== 'undefined') {
    return localStorage.getItem(key)
  }
  else {
    return null
  }
}

export function setItem(key, data) {
  if (typeof window !== 'undefined') {
    localStorage.setItem(key, data);
  }
}

export function clearStorage() {
  if (typeof window !== 'undefined') {
    localStorage.clear();
  }
}