import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Avatar, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import logo from '../../LetsPlanLogo.png';

import { LogOut } from '../../actions/authAction';
import './dashHeader.css';
import { history } from '../../store/configureStore';
import { clearStorage } from '../../utils/localStorageUtils';

class DashHeader extends Component {

  handleLogOut = () => {
    clearStorage();
    this.props.LogOut();
    history.push('/');
  }

  handleLogoClick = () => {
    history.push('/dashboard');
  };

  render() {
    const { auth } = this.props;
    const name = auth.userDetail ? auth.userDetail.userName.match(/\b(\w)/g).join('') : '';
    return (
      <div>
        <div className="navbar-menu">
          <img onClick={this.handleLogoClick} src={logo} alt='Logo here' height="40" />
          <Button size="large" style={{ color: 'white' }} onClick={this.handleLogoClick}>
            All Plans
          </Button>
          <div className="sub-nav-bar">
            <ul>
              {
                auth.userDetail && <li className="dropbtn">
                  <Avatar className="avatar">
                    {name}
                  </Avatar>
                  <ul>
                    <li className="name"><span>{auth.userDetail.userName}</span></li>
                    <li onClick={this.handleLogOut}><span>Log Out</span></li>
                  </ul>
                </li>
              }
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.login_data
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    LogOut
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(DashHeader);
