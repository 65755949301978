import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import _ from 'lodash';

import Header from '../header/header';
import {ResetPasswordAction, updatePasswordAction} from '../../actions/resetPasswordAction';
import {Button, Card, TextBox} from '../../theme';
import {InputAdornment, Typography} from '@material-ui/core';
import {Lock} from '@material-ui/icons';
import {validatePassword} from '../../utils/validation';
import {history} from '../../store/configureStore';
import './resetPassword.css';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      confirmPassword: '',
      updated: false,
      isLoading: true,
      errors: {}
    };
  }

  async componentDidMount() {
    const {
      match: {
        params: {token}
      }
    } = this.props;
    let response;
    const {ResetPasswordAction} = this.props;
    try {
      response = await ResetPasswordAction(token);
      if (!response.error) {
        this.setState({
          username: response.username,
          updated: response.updated,
          isLoading: response.isLoading,
          error: response.error
        });
      } else {
        this.setState({
          updated: response.updated,
          isLoading: response.isLoading,
          error: response.error
        });
      }
    } catch (err) {
      console.log('error', err);
    }
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
      errors: {}
    });
  };

  handleForgotPassword = (e) => {
    e.preventDefault();
    history.push('/forgotPassword');
  };

  updatePassword = async (e) => {
    e.preventDefault();
    const {username, password, confirmPassword} = this.state;
    const {updatePasswordAction} = this.props;
    const {
      match: {
        params: {token}
      }
    } = this.props;
    try {
      const passwordValidation = validatePassword(password);
      if (passwordValidation) {
        this.setState ((state) => ({
          errors: {...state.errors, password: {isError: true, errmsg: passwordValidation}}
        }));
      }
      if (!passwordValidation && _.isEqual(password, confirmPassword)) {
        const response = await updatePasswordAction({username, password, token});
        if (!response.error) {
          this.setState({
            updated: true,
            error: false
          });
        } else {
          this.setState({
            updated: false,
            error: true
          });
        }
      } else {
        this.setState({password: '', confirmPassword: ''});
        alert('Password and confirm password should be same');
      }
    } catch (error) {
      this.setState({
        updated: false,
        error: true
      });
      console.log(error);
    }
  };

  render() {
    const {
      password, confirmPassword, error, isLoading, updated, errors = {}
    } = this.state;

    if (error) {
      return (
        <div>
          <Header/>
          <div className="reset-problem-div">
            <h4>Problem resetting password. Please send another reset link.</h4>
            <div className='reset-main-content'>
              <div className='card-content-link mb'>
                <span onClick={this.handleForgotPassword}>Forgot Password</span>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (isLoading) {
      return (
        <div>
          <Header/>
          <div style={{letterSpacing: 0, color: '#494949'}}>Loading User Data...</div>
        </div>
      );
    }
    return (
      <div>
        <Header/>
        <div className='reset-password background-wrapper'>
          <Card>
            <div className='card-content'>
              <Typography gutterBottom variant="h4" component="h2" className='reset-password-title card-title'>
                Reset Password
              </Typography>
              { !updated ?
                <div className='mb'>
                  <div className='reset-input'>
                    <TextBox
                      id="password"
                      name="password"
                      label="password"
                      value={password}
                      onChange={this.handleChange('password')}
                      type="password"
                      placeholder="New password"
                      error={errors.password && errors.password.isError}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Lock color="primary"/>
                          </InputAdornment>
                        ),
                        style: {
                          fontSize: 18,
                          margin: '20px 8px 8px'
                        }
                      }}
                    />
                    {errors.password && <span className='errors'>{errors.password.errmsg}</span>}
                  </div>
                  <div className='reset-input'>
                    <TextBox
                      id="confirmPassword"
                      name="confirmPassword"
                      label="confirmPassword"
                      value={confirmPassword}
                      onChange={this.handleChange('confirmPassword')}
                      type="password"
                      placeholder="Confirm password" InputProps={{
                        startAdornment: (
                          <InputAdornment posiclassName='reset-input'tion="start">
                            <Lock color="primary"/>
                          </InputAdornment>
                        ),
                        style: {
                          fontSize: 18,
                          margin: '20px 8px 8px'
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          this.updatePassword(e);
                        }
                      }}
                    />
                    {errors.password && <span className='errors'>{errors.password.errmsg}</span>}
                  </div>
                  <div className='btnWrapper'>
                    <Button onClick={this.updatePassword}
                      label="Update Password"/>
                  </div>
                </div>
                :
                <div>
                  <span style={{letterSpacing: 0, color: '#494949'}}>
                  Your password has been successfully reset, please try logging in
                  again.
                  </span>
                </div>
              }
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators ({
    ResetPasswordAction,
    updatePasswordAction
  }, dispatch);
};

export default connect(null, mapDispatchToProps)(ResetPassword);
