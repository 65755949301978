import {LOADING, FETCH_ALL_PLANS, STORE_PATH_ID, SET_INVITE_FLAG, FETCH_PLAN_LOCATIONS} from './actionTypes';
import {Plan, PlanLocation} from '../lib/model/index';

export const fetchAllPlans = (userDetail) => {
  const email = userDetail.userEmail;
  const name = userDetail.userName;
  return async (dispatch) => {
    try {
      dispatch({type: LOADING, payload: true});
      const plans = await Plan.getPlanByEmail(email, name);
      let planObjects = plans && plans.map((plan) => { return plan.data; });
      dispatch({type: FETCH_ALL_PLANS, payload: planObjects});
      dispatch({type: LOADING, payload: false});
    } catch (error) {
      dispatch({type: FETCH_ALL_PLANS, payload: []});
      dispatch({type: LOADING, payload: false});
      console.log('Error!!', error);
    }
  };
};

export const storePlanId = (planId) => {
  return {
    type: STORE_PATH_ID,
    payload: planId
  };
};

export const setInviteFlag = (isInvite) => {
  return {
    type: SET_INVITE_FLAG,
    payload: isInvite
  };
};

export const fetchPlanLocations = (planId) => {
  return async (dispatch) => {
    try {
      dispatch({type: LOADING, payload: true});
      const planLocations = await PlanLocation.getPlanLocationById(planId);
      let planLocationObjects =  planLocations && planLocations.map((loc) => { return loc.data; });
      planLocationObjects && planLocationObjects.sort((a, b) => {
        if (a.up_votes.length === b.up_votes.length) {
          return a.location.localeCompare(b.location);
        }
        return b.up_votes.length - a.up_votes.length;
      });
      const planObj = await Plan.getByPlanId(planId);
      planObj.venue = (planLocationObjects && planLocationObjects[0].location) || 'To be decided yet';
      await planObj.save();
      dispatch({type: FETCH_PLAN_LOCATIONS, payload: planLocationObjects});
      dispatch({type: LOADING, payload: false});
    } catch (error) {
      dispatch({type: LOADING, payload: false});
      dispatch({type: FETCH_PLAN_LOCATIONS, payload: []});
      console.log('Error!!', error);
    }
  };
};

export const addPlanLocation = (planId, planLocation, userId) => {
  return async () => {
    try {
      const isLocationObject = await PlanLocation.getPlanLocationById(planId);
      const isLocationExist = isLocationObject && isLocationObject.find((loc) => loc.location === planLocation);
      if (!isLocationObject || !isLocationExist) {
        const newLocation = new PlanLocation('');
        newLocation.plan_id = planId;
        newLocation.location = planLocation;
        newLocation.up_votes = [userId];
        newLocation.down_votes = [];
        await newLocation.save();
      } else {
        return 'Already Exists';
      }
    } catch (error) {
      console.log('Error!!', error);
    }
  };
};

export const handleVotes = (planId, location, userId, voteType) => {
  return async () => {
    try {
      await PlanLocation.updateVotes(planId, location, userId, voteType);
    } catch (error) {
      console.log('Error!!', error);
    }
  };
};
