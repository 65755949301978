Object.defineProperty(exports, '__esModule', {value: true});
var firebase_admin_1 = require('firebase');
var moment = require('moment');
var Plan = /** @class */ (function () {
  function Plan(snapshot) {
    if (snapshot instanceof firebase_admin_1.firestore.DocumentSnapshot) {
      this._id = snapshot.id;
      this._ref = snapshot.ref;
      this._data = snapshot.data();
    } else if (snapshot) {
      this._id = snapshot.id;
      this._data = snapshot;
      this._ref = Plan.colRef().doc(snapshot.id);
      if (snapshot.date_created) {
        this.date_created = moment(new firebase_admin_1.firestore.Timestamp(snapshot.date_created._seconds, snapshot.date_created._nanoseconds).toMillis());
      }
      if (snapshot.date_modified) {
        this.date_modified = moment(new firebase_admin_1.firestore.Timestamp(snapshot.date_modified._seconds, snapshot.date_modified._nanoseconds).toMillis());
      }
    } else {
      this._ref = Plan.colRef().doc();
      this._id = this._ref.id;
      this._data = {};
      this.is_delete = false;
      this.date_created = moment();
    }
  }
  Plan.colRef = function () {
    return firebase_admin_1.firestore().collection('plan');
  };
  Plan.prototype.save = function () {
    this.date_modified = moment();
    this._ref.set(this._data);
  };
  Object.defineProperty(Plan.prototype, 'id', {
    get: function () {
      return this._id;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(Plan.prototype, 'data', {
    get: function () {
      return this._data;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(Plan.prototype, 'ref', {
    get: function () {
      return this._ref;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(Plan.prototype, 'plan_unique_id', {
    get: function () {
      return this._data.plan_unique_id;
    },
    set: function (planId) {
      this._data.plan_unique_id = planId;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(Plan.prototype, 'plan_title', {
    get: function () {
      return this._data.plan_title;
    },
    set: function (planTitle) {
      this._data.plan_title = planTitle;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(Plan.prototype, 'plan_description', {
    get: function () {
      return this._data.plan_description;
    },
    set: function (planDescription) {
      this._data.plan_description = planDescription;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(Plan.prototype, 'plan_creator_name', {
    get: function () {
      return this._data.plan_creator_name;
    },
    set: function (planCreatorName) {
      this._data.plan_creator_name = planCreatorName;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(Plan.prototype, 'plan_creator_id', {
    get: function () {
      return this._data.plan_creator_id;
    },
    set: function (planCreatorId) {
      this._data.plan_creator_id = planCreatorId;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(Plan.prototype, 'plan_url', {
    get: function () {
      return this._data.plan_url;
    },
    set: function (planUrl) {
      this._data.plan_url = planUrl;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(Plan.prototype, 'plan_type', {
    get: function () {
      return this._data.plan_type;
    },
    set: function (planType) {
      this._data.plan_type = planType;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(Plan.prototype, 'venue', {
    get: function () {
      return this._data.venue;
    },
    set: function (venue) {
      this._data.venue = venue;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(Plan.prototype, 'time_period', {
    get: function () {
      return this._data.time_period;
    },
    set: function (timePeriod) {
      this._data.time_period = timePeriod;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(Plan.prototype, 'participants', {
    get: function () {
      return this._data.participants;
    },
    set: function (participants) {
      this._data.participants = participants;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(Plan.prototype, 'participants_email', {
    get: function () {
      return this._data.participants_email;
    },
    set: function (participantsEmail) {
      this._data.participants_email = participantsEmail;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(Plan.prototype, 'no_of_participants', {
    get: function () {
      return this._data.no_of_participants;
    },
    set: function (noOfParticipants) {
      this._data.no_of_participants = noOfParticipants;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(Plan.prototype, 'no_of_confirmations', {
    get: function () {
      return this._data.no_of_confirmations;
    },
    set: function (noOfConfirmations) {
      this._data.no_of_confirmations = noOfConfirmations;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(Plan.prototype, 'is_delete', {
    get: function () {
      return this._data.is_delete;
    },
    set: function (isDelete) {
      this._data.is_delete = isDelete;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(Plan.prototype, 'date_created', {
    get: function () {
      return moment(this._data.date_created.toMillis());
    },
    set: function (dateCreated) {
      this._data.date_created = firebase_admin_1.firestore.Timestamp.fromMillis(dateCreated.valueOf());
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(Plan.prototype, 'date_modified', {
    get: function () {
      return moment(this._data.date_modified.toMillis());
    },
    set: function (dateModified) {
      this._data.date_modified = firebase_admin_1.firestore.Timestamp.fromMillis(dateModified.valueOf());
    },
    enumerable: true,
    configurable: true
  });
  Plan.getById = function (id) {
    return new Promise(function (resolve, reject) {
      try {
        Plan.colRef()
          .doc(id)
          .get()
          .then(function (document) {
            if (document.exists) {
              resolve(new Plan(document));
            } else {
              resolve();
            }
          })
          .catch(function (err) {
            reject(err);
          });
      } catch (ex) {
        reject(ex);
      }
    });
  };
  Plan.getByPlanId = function (planId) {
    return new Promise(function (resolve, reject) {
      try {
        Plan.colRef()
          .where('plan_unique_id', '==', planId)
          .get()
          .then(function (document) {
            if (document.empty) {
              resolve();
            } else {
              resolve(new Plan(document.docs[0]));
            }
          })
          .catch(function (err) {
            reject(err);
          });
      } catch (ex) {
        reject(ex);
      }
    });
  };
  Plan.getPlanByEmail = function (email) {
    return new Promise(function (resolve, reject) {
      try {
        Plan.colRef()
          .where('participants_email', 'array-contains', email)
          .get()
          .then(function (documentData) {
            if (documentData.empty) {
              resolve();
            } else {
              resolve(documentData.docs.map(function (d) { return new Plan(d); }));
            }
          })
          .catch(function (err) {
            reject(err);
          });
      } catch (ex) {
        reject(ex);
      }
    });
  };
  return Plan;
}());
exports.default = Plan;
