import React, {Component} from 'react';
import {connect} from 'react-redux';

import MultiDay from '../../date-range/demo/src/components/multiDay';
import SingleDay from '../../date-range/demo/src/components/singleDay';
import {Plan} from '../../../lib/model';

class When extends Component {

  constructor(props) {
    super(props);
    this.state = {
      planType: ''
    };
  }

  async componentDidMount() {
    const {planId} = this.props;
    const planObj = await Plan.getByPlanId(planId);
    this.setState({planType: planObj.plan_type});
  }

  render() {
    const {planType} = this.state;
    const {planId} = this.props;
    return (
      <div>
        <div>
          {
            planType === 'multi' ?
              <div>
                <MultiDay planId={planId}/>
              </div>
              :
              <div>
                <SingleDay planId={planId}/>
              </div>
          }
        </div>
      </div>
    );
  }
}

export default connect(null, null)(When);
