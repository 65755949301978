import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Avatar, CircularProgress, withStyles
} from '@material-ui/core';
import { ArrowRightAlt } from '@material-ui/icons';
import _ from 'lodash';
import { Helmet } from 'react-helmet';

import './planSummary.css';
import { Mixpanel } from '../analytics/Mixpanel';

const styles = ((theme) => ({
  root: {
    width: '100%',
    margin: '0 auto',
    padding: '24px',
    maxWidth: '320px'
  },
  joinNow: {
    borderRadius: '3rem',
    backgroundColor: 'white',
    color: '#1c97f3',
    '&:hover': {
      backgroundColor: 'white',
      color: '#1c97f3',
      borderColor: theme.palette.primary.main
    }
  },
  loader: {
    position: 'fixed',
    top: '50%',
    left: '30%'
  }
}));

class PlanSummary extends Component {

  constructor(props) {
    super(props);
    this.state = {
      planObj: {}
    };
  }

  async UNSAFE_componentWillMount() {
    Mixpanel.track('Joined plan');
    const { plan } = this.props;
    this.setState({
      planObj: plan
    });
  }

  render() {
    const { classes } = this.props;
    const { planObj } = this.state;
    const name = planObj && planObj.plan_creator_name && planObj.plan_creator_name.match(/\b(\w)/g).join('');
    if (!_.isEmpty(planObj)) {
      return (
        <div className='description-div'>
          <Helmet>
            <title>{`Let's Plan ${planObj.plan_title} with ${planObj.plan_creator_name}`}</title>
          </Helmet>
          <div className="plan-content-text">
            <div className="who-define-header">
              <h2>Let's Plan "{planObj.plan_title}" with "{planObj.plan_creator_name}"</h2>
              <span>{planObj.plan_description}</span>
              <h4>Sign up or Login to Help Plan</h4>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className='description-div loader'>
          <CircularProgress className={classes.loader} size={40} />
        </div>);
    }
  }
}

export default withStyles(styles)(connect(null, null)(PlanSummary));
