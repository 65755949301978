import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Calendar from './Calendar.js';
import {rangeShape} from './DayCell';
import {findNextRangeIndex, generateStyles} from '../utils.js';
import {isBefore, isWithinInterval} from 'date-fns';
import classnames from 'classnames';
import coreStyles from '../styles';

class DateRange extends Component {
  constructor(props, context) {
    super(props, context);
    this.setSelection = this.setSelection.bind(this);
    this.handleRangeFocusChange = this.handleRangeFocusChange.bind(this);
    this.updatePreview = this.updatePreview.bind(this);
    this.calcNewSelection = this.calcNewSelection.bind(this);
    this.state = {
      focusedRange: props.initialFocusedRange || [findNextRangeIndex(props.ranges), 0],
      preview: null
    };
    this.styles = generateStyles([coreStyles, props.classNames]);
  }
  calcNewSelection(value, isSingleValue = true) {
    const {disabledDates} = this.props;
    let startDate, endDate;
    if (!isSingleValue) {
      startDate = value.startDate;
      endDate = value.endDate;
    }  else if (isSingleValue) {
      startDate = value;
      endDate = value;
    }  else { endDate = value; }
    if (isBefore(endDate, startDate)) {
      [startDate, endDate] = [endDate, startDate];
    }

    const inValidDatesWithinRange = disabledDates.filter((disabledDate) =>
      isWithinInterval(disabledDate, {
        start: startDate,
        end: endDate
      })
    );
    return {
      wasValid: !(inValidDatesWithinRange.length > 0),
      range: {startDate, endDate}
    };
  }
  setSelection(value, isSingleValue = true) {
    const {onChange} = this.props;
    const newSelection = this.calcNewSelection(value, isSingleValue);
    onChange({
      'select': {
        ...newSelection.range
      }
    });
    this.setState({
      preview: null
    });
  }
  handleRangeFocusChange(focusedRange) {
    this.setState({focusedRange});
    this.props.onRangeFocusChange && this.props.onRangeFocusChange(focusedRange);
  }
  updatePreview(val) {
    if (!val) {
      this.setState({preview: null});
      return;
    }
    const {rangeColors} = this.props;
    const color = rangeColors[0];
    this.setState({preview: {...val.range, color}});
  }
  render() {
    return (
      <Calendar
        focusedRange={this.state.focusedRange}
        onRangeFocusChange={this.handleRangeFocusChange}
        preview={this.state.preview}
        onPreviewChange={(value) => {
          this.updatePreview(value ? this.calcNewSelection(value) : null);
        }}
        {...this.props}
        displayMode="dateRange"
        className={classnames(this.styles.dateRangeWrapper, this.props.className)}
        onChange={this.setSelection}
        updateRange={(val) => this.setSelection(val, false)}
        ref={(target) => {
          this.calendar = target;
        }}
      />
    );
  }
}

DateRange.defaultProps = {
  classNames: {},
  ranges: [],
  moveRangeOnFirstSelection: false,
  rangeColors: ['#3d91ff', '#3ecf8e', '#fed14c'],
  disabledDates: []
};

DateRange.propTypes = {
  ...Calendar.propTypes,
  onChange: PropTypes.func,
  onRangeFocusChange: PropTypes.func,
  className: PropTypes.string,
  ranges: PropTypes.arrayOf(rangeShape),
  moveRangeOnFirstSelection: PropTypes.bool
};

export default DateRange;
