import _ from 'lodash';
import {
  FETCH_ALL_PLANS,
  STORE_PATH_ID,
  SET_INVITE_FLAG,
  FETCH_PLAN_LOCATIONS,
  FETCH_ALL_PLAN_TIME_PERIODS,
  ADD_NEW_TIME_PERIOD_OPTION,
  MODIFY_TIME_PERIOD,
  DELETE_TIME_PERIOD,
  FETCH_ALL_MESSAGES,
  ADD_NEW_MESSAGE
} from '../actions/actionTypes';
import { INITIAL_STATE } from '../service/initialState';

export default (state = INITIAL_STATE.plan, { type, payload }) => {
  switch (type) {
    case FETCH_ALL_PLANS: {
      return {
        ...state,
        planList: payload
      };
    }
    case STORE_PATH_ID: {
      return {
        ...state,
        planId: payload
      };
    }
    case SET_INVITE_FLAG: {
      return {
        ...state,
        isInvite: payload
      };
    }
    case FETCH_PLAN_LOCATIONS: {
      return {
        ...state,
        locations: payload
      };
    }
    case 'STORE_PLAN_DETAILS': {
      return {
        ...state,
        metaData: payload
      }
    }
    case FETCH_ALL_PLAN_TIME_PERIODS: {
      let { singleTimePeriods, multiTimePeriods } = state;
      singleTimePeriods = [];
      multiTimePeriods = [];
      payload && payload.forEach((d) => {
        if (d.date_type === 'multi') {
          multiTimePeriods.push(d);
        } else {
          singleTimePeriods.push(d);
        }
      });
      return {
        ...state, singleTimePeriods, multiTimePeriods
      };
    }
    case ADD_NEW_TIME_PERIOD_OPTION: {
      return {
        ...state,
        singleTimePeriods: [...state.singleTimePeriods, payload]
      };
    }
    case MODIFY_TIME_PERIOD: {
      const newArray = _.map(state.singleTimePeriods, (obj) => {
        if (obj.id === payload.id) {
          return payload;
        } else { return obj; }
      });
      return {
        ...state,
        singleTimePeriods: newArray
      };
    }
    case DELETE_TIME_PERIOD: {
      const { singleTimePeriods } = state;
      const newArray = singleTimePeriods.filter((obj) => obj.id !== payload);
      return {
        ...state,
        singleTimePeriods: newArray
      };
    }
    case FETCH_ALL_MESSAGES: {
      return {
        ...state,
        messages: payload
      };
    }
    case ADD_NEW_MESSAGE: {
      return {
        ...state,
        messages: [...state.messages, payload]
      };
    }
    default:
      return state;
  }
};
