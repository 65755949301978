import axios from 'axios';

let baseURL = 'http://localhost:8080';
const environment = process.env.REACT_APP_ENVIRONMENT;

if (environment === 'development') {
  baseURL = process.env.REACT_APP_STAGING_BASE_URL;
} else if (environment === 'production') {
  baseURL = process.env.REACT_APP_PRODUCTION_BASE_URL;
}

export const axiosInstance = axios.create({
  baseURL: baseURL
});
