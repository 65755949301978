import {LOGIN_SUCCESS, LOGIN_FAIL} from '../actions/actionTypes';
import {INITIAL_STATE} from '../service/initialState';

export default (state = INITIAL_STATE.auth, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      return {
        ...state,
        login_data: {
          token: action.data.token,
          userDetail: {
            userId: action.data.userId,
            userName: action.data.userName,
            userEmail: action.data.userEmail
          }
        }
      };
    }
    case LOGIN_FAIL: {
      return {
        ...state,
        login_error: action.login_error,
        login_data: {}
      };
    }
    default:
      return state;
  }

};
