import React, {Component, Segment} from 'react';
import {connect} from 'react-redux';
import clsx from 'clsx';
import {
  withStyles,
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  Tab, Tabs,
  Divider,
  IconButton,
  Button,
  Typography
} from '@material-ui/core';
import {Menu, ChevronRight} from '@material-ui/icons';
import ChatIcon from '@material-ui/icons/Chat';

import PlanInfo from './planInfo/planInfo';
import PlanSummary from './planSummary/planSummary';

import './what.css';
import Chat from './chat/chat';
import Who from './who/who';

const drawerWidth = 340;

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const styles = ((theme) => ({
  appBar: {
    transition: theme.transitions.create(['margin', 'drawerWidth'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'darwerWidth'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 240px)',
      marginLeft: `calc(${drawerWidth}px - 100px)`
    },
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% - 240px)',
      marginLeft: `calc(${drawerWidth}px - 100px)`
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    position: 'absolute',
    border: '1px solid #dadada',
    [theme.breakpoints.down('sm')]: {
      width: `calc(${drawerWidth}px - 100px)`
    },
    [theme.breakpoints.down('xs')]: {
      width: '80vw',
      marginLeft: 0
    }
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    border: '1px solid #ededed'
  },
  contentShift: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0,
    border: '1px solid #ededed'
  },
  drawerOpen: {
    display: 'block',
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['all'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    [theme.breakpoints.down('sm')]: {
      marginLeft: `calc(${drawerWidth}px - 100px)`
    },
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% - 240px)',
      marginLeft: '0'
    }
  },
  drawerClose: {
    display: 'none'
  },
  indicator: {
    backgroundColor: 'transparent'
  },
  tab: {
    opacity: 1,
    textTransform: 'capitalize',
    fontSize: '100%',
    '&:hover': {
      color: 'white'
    },
    '&.Mui-selected': {
      color: '#2094FA',
      backgroundColor: '#fff'
    }
  },
  header1: {
    color: '#fff',
    marginLeft: '0%'
  },
  button: {
    fontSize: '12px'
  }
}));

class What extends Component {

  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: true,
      tabsValue: 0
    };
  }

  handleDrawerOpen = () => {
    this.setState({drawerOpen: true});
  };

  handleDrawerClose = () => {
    this.setState({drawerOpen: false});
  };

  handleChatClick = () => {
    this.setState({tabsValue: 0});
  };

  handleWhoClick = () => {
    this.setState({tabsValue: 1});
  };

  render() {
    const {classes, planId} = this.props;
    const {drawerOpen, tabsValue} = this.state;
    return (

      <div>
      <div className="main-summary-wrapper">
          <PlanSummary planId={planId} handlePlannersClick={this.handleWhoClick}/>
      </div>
      <div className="main-root-wrapper">
        <CssBaseline />

        <AppBar position="absolute"
          className={clsx(classes.appBar)}>
          <Toolbar disableGutters>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, drawerOpen && classes.hide)}
            >
              <Menu />
            </IconButton>
            <div style={{marginLeft: 10}}>
              <Tabs
                variant="fullWidth"
                classes={{indicator: classes.indicator}}
                className={classes.header1}
                value={tabsValue}
              >
                <LinkTab label="Chat" onClick={this.handleChatClick} className={classes.tab} />
                <LinkTab label="Planners" onClick={this.handleWhoClick} className={classes.tab} />
              </Tabs>
            </div>
          </Toolbar>
        </AppBar>

        <main
          className={clsx(classes.content, {
            [classes.contentShift]: drawerOpen
          })}
        >
          {tabsValue === 0 ? <Chat planId={planId} /> : <Who planId={planId}/>}
        </main>
      </div>
      </div>
    );
  }
}

export default withStyles(styles)(connect(null, null)(What));
