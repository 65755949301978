export const SIGN_UP_FAIL = 'SIGN_UP_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOG_OUT = 'LOGOUT';

export const LOADING = 'LOADING';
export const SIGN_UP_LOADING = 'SIGN_UP_LOADING';
export const SIGN_IN_LOADING = 'SIGN_IN_LOADING';

export const FETCH_ALL_PLANS = 'FETCH_ALL_PLANS';
export const STORE_PATH_ID = 'STORE_PATH_ID';
export const SET_INVITE_FLAG = 'SET_INVITE_FLAG';
export const FETCH_PLAN_LOCATIONS = 'FETCH_PLAN_LOCATIONS';
export const FETCH_ALL_PLAN_TIME_PERIODS = 'FETCH_ALL_PLAN_TIME_PERIODS';

export const ADD_NEW_TIME_PERIOD_OPTION = 'ADD_NEW_TIME_PERIOD_OPTION';
export const MODIFY_TIME_PERIOD = 'MODIFY_TIME_PERIOD';
export const DELETE_TIME_PERIOD = 'DELETE_TIME_PERIOD';

export const FETCH_ALL_MESSAGES = 'FETCH_ALL_MESSAGES';
export const ADD_NEW_MESSAGE = 'ADD_NEW_MESSAGE';
