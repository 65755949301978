import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getItem } from '../../utils/localStorageUtils';

class PublicRoute extends Component {
  render() {
    const token = getItem('token');
    if (token) {
      return (<Redirect to={'/dashboard'} />);
    } else {
      return (<Route {...this.props} />);
    }
  }
}

export default PublicRoute;
