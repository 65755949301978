import React, {PureComponent} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Dialog, IconButton, Typography} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import {Close} from '@material-ui/icons';
import uuid from 'uuid';

import {history} from '../../../store/configureStore';
import {Button, TextBox} from '../../../theme';
import {Plan} from '../../../lib/model';
import {validateName} from '../../../utils/validation';
import {baseInviteLink} from '../../../config/inviteLinkConfig';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    backgroundColor: '#2094FA',
    color: '#fff'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0),
    color: theme.palette.grey[500]
  },
  btnWrapper: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
    '& button': {
      background: '#2094FA',
      padding: '3px 10px',
      '&:hover': {
        background: '#2094FA'
      }
    }
  },
  modalTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    '& h6': {
      fontSize: '16px',
      fontWeight: 600
    }
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const {children, classes, onClose} = props;
  return (
    <MuiDialogTitle className={`${classes.modalTitle} ${classes.root}`} disableTypography>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    textAlign: 'center'
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  }
}))(MuiDialogActions);

class AddPlanDialog extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      planTitle: '',
      planDescription: '',
      errors: {}
    };
  }

  handleContinue = async () => {
    const {auth: {userDetail: {userEmail, userName} = {}} = {}, storePlanId} = this.props;
    const {planTitle, planDescription} = this.state;
    const planTitleValidation = validateName(planTitle);
    if (planTitleValidation) {
      this.setState ((state) => ({
        errors: {...state.errors, plan_title: {isError: true, errmsg: 'Required field Title'}}
      }));
    }
    if (!planTitleValidation) {
      try {
        const uniquePlanId = uuid();
        const planObj = await new Plan('');
        planObj.plan_url = `${baseInviteLink}/plan/${uniquePlanId}?invite=${userEmail}`;
        planObj.plan_unique_id = uniquePlanId;
        planObj.plan_creator_name = userName;
        planObj.plan_creator_id = userEmail;
        planObj.plan_title = planTitle;
        planObj.plan_description = planDescription;
        planObj.participants = [userName];
        planObj.participants_email = [userEmail];
        await planObj.save();
        storePlanId(uniquePlanId);
        history.push(`/plan/${uniquePlanId}/what`);
      } catch (err) {
        console.log(err);
      }
    } else {
      this.setState({
        planTitle: '',
        planDescription: ''
      });
    }
  }

  handleChange = ({target}) => {
    this.setState({
      [target.name]: target.value
    });
  };

  render() {
    const {open, onClose, classes} = this.props;
    const {planTitle, planDescription, errors} = this.state;
    return (
      <div>
        <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open} maxWidth={'xs'} fullWidth>
          <DialogTitle  id="customized-dialog-title" onClose={onClose}>
                Add Plan Title and Description
          </DialogTitle>
          <DialogContent dividers>
            <div className="who-define">
              <h2>Title?</h2>
              <TextBox
                id="plan_title"
                name="planTitle"
                value={planTitle}
                placeholder="Enter Plan Title"
                fullWidth={true}
                onChange={this.handleChange}
                error = {errors.plan_title && errors.plan_title.isError }
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    this.handleContinue();
                  }
                }}
              />
              <span className='errors'>{errors.plan_title && errors.plan_title.errmsg}</span>
            </div>
            <div className="who-define">
              <h2>Description?</h2>
              <TextBox
                id="plan_description"
                name="planDescription"
                value={planDescription}
                placeholder="Enter Plan Description"
                fullWidth={true}
                onChange={this.handleChange}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    this.handleContinue();
                  }
                }}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <div className={classes.btnWrapper}>
              <Button onClick={this.handleContinue}>
                Continue
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(AddPlanDialog);
