import React, { Component } from 'react';
import { connect } from 'react-redux';

import Header from '../header/header';
import SignUp from '../signup/signUp';
import './login.css';
import queryString from 'query-string';
import PlanSummary from '../planSummary/planSummary';
import { bindActionCreators } from 'redux';
import { setInviteFlag, storePlanId } from '../../actions/planAction';
import { loadingAction } from '../../actions/authAction';
import { Plan } from '../../lib/model';
import { history } from '../../store/configureStore';
import { CircularProgress, withStyles } from '@material-ui/core';

const arr = ['Meeting', 'Call', 'Coffee', 'Lunch', 'Hike', 'Trek', 'Vacation', 'Run', 'Event'];

const styles = (() => ({
  loader: {
    position: 'fixed',
    top: '50%',
    left: '50%'
  }
}));

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: 'Meeting',
      isInvite: false,
      plan: {}
    };
  }

  async componentDidMount() {
    this.interval = setInterval(this.getArrValue, 2000);
    const values = queryString.parse(this.props.location.search);
    const { auth: { userDetail } = {}, match: { params: { id } = {} } = {}, storePlanId, setInviteFlag } = this.props;
    try {
      await storePlanId(id);
      if (values.invite) {
        this.props.loadingAction(true);
        setInviteFlag(true);
        this.setState({ isInvite: true });
        const planObj = await Plan.getByPlanId(id);
        this.setState({ plan: planObj });
        if (userDetail && userDetail.userName) {
          const isParticipantExist = (userDetail && planObj.participants) && planObj.participants.includes(userDetail.userName);
          if (isParticipantExist) {
            history.push(`/plan/${id}/what`);
          } else {
            let participants = planObj.participants;
            let participantsEmail = planObj.participants_email;
            if (participants !== undefined && participantsEmail !== undefined && !participants.includes(userDetail.userName)) {
              participants.push(userDetail.userName);
              participantsEmail.push(userDetail.userEmail);
            } else {
              participants = [userDetail.userName];
              participantsEmail = [userDetail.userEmail];
            }
            planObj.participants = participants;
            planObj.participants_email = participantsEmail;
            await planObj.save();
            history.push(`/plan/${id}/what`);
          }
        }
        this.props.loadingAction(false);
      } else {
        this.props.loadingAction(false);
        setInviteFlag(false);
        this.setState({ isInvite: false });
      }
    } catch (err) {
      console.log(err);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getArrValue = () => {
    const value = arr[Math.floor(Math.random() * arr.length)];
    this.setState({ value });
  }

  async UNSAFE_componentWillMount() {
    if (typeof window !== 'object') {
      const values = queryString.parse(this.props.location.search);
      if (values.invite) {
        setInviteFlag(true);
        this.setState({ isInvite: true, plan: this.props.plan.metaData });
      }
    }
  }

  render() {
    const { isInvite, plan } = this.state;
    const { classes, loading } = this.props;
    return (
      !loading ? <>
        <Header />
        <div className="content-body-container">
          {isInvite ?
            <PlanSummary plan={plan} /> :
            <div className="description-div">
              <div className="desc-content-text">
                <div>
                  <h1>Plan Your Next {this.state.value}</h1>
                  <h2>Without The Messy Back & Forth</h2>
                </div>
              </div>
            </div>}
          <div className="form-wrapper">
            <SignUp />
          </div>
        </div>
      </>
        : <CircularProgress className={classes.loader} size={50} />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.login_data,
    plan: state.plan,
    loading: state.loading.pageLoader
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setInviteFlag,
    storePlanId,
    loadingAction
  }, dispatch);
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Login));
