Object.defineProperty(exports, '__esModule', {value: true});
var firebase_admin_1 = require('firebase');
var _ = require('lodash');
var moment = require('moment');
var PlanLocation = /** @class */ (function () {
  function PlanLocation(snapshot) {
    if (snapshot instanceof firebase_admin_1.firestore.DocumentSnapshot) {
      this._id = snapshot.id;
      this._ref = snapshot.ref;
      this._data = snapshot.data();
    } else if (snapshot) {
      this._id = snapshot.id;
      this._data = snapshot;
      this._ref = PlanLocation.colRef().doc(snapshot.id);
      if (snapshot.date_created) {
        this.date_created = moment(new firebase_admin_1.firestore.Timestamp(snapshot.date_created._seconds, snapshot.date_created._nanoseconds).toMillis());
      }
      if (snapshot.date_modified) {
        this.date_modified = moment(new firebase_admin_1.firestore.Timestamp(snapshot.date_modified._seconds, snapshot.date_modified._nanoseconds).toMillis());
      }
    } else {
      this._ref = PlanLocation.colRef().doc();
      this._id = this._ref.id;
      this._data = {};
      this.is_delete = false;
      this.date_created = moment();
    }
  }
  PlanLocation.colRef = function () {
    return firebase_admin_1.firestore().collection('plan_location');
  };
  PlanLocation.prototype.save = function () {
    this.date_modified = moment();
    this._ref.set(this._data);
  };
  Object.defineProperty(PlanLocation.prototype, 'id', {
    get: function () {
      return this._id;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PlanLocation.prototype, 'data', {
    get: function () {
      return this._data;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PlanLocation.prototype, 'ref', {
    get: function () {
      return this._ref;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PlanLocation.prototype, 'plan_id', {
    get: function () {
      return this._data.plan_id;
    },
    set: function (planId) {
      this._data.plan_id = planId;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PlanLocation.prototype, 'plan_url', {
    get: function () {
      return this._data.plan_url;
    },
    set: function (planUrl) {
      this._data.plan_url = planUrl;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PlanLocation.prototype, 'location', {
    get: function () {
      return this._data.location;
    },
    set: function (location) {
      this._data.location = location;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PlanLocation.prototype, 'up_votes', {
    get: function () {
      return this._data.up_votes;
    },
    set: function (upVotes) {
      this._data.up_votes = upVotes;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PlanLocation.prototype, 'down_votes', {
    get: function () {
      return this._data.down_votes;
    },
    set: function (downVotes) {
      this._data.down_votes = downVotes;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PlanLocation.prototype, 'is_delete', {
    get: function () {
      return this._data.is_delete;
    },
    set: function (isDelete) {
      this._data.is_delete = isDelete;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PlanLocation.prototype, 'date_created', {
    get: function () {
      return moment(this._data.date_created.toMillis());
    },
    set: function (dateCreated) {
      this._data.date_created = firebase_admin_1.firestore.Timestamp.fromMillis(dateCreated.valueOf());
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(PlanLocation.prototype, 'date_modified', {
    get: function () {
      return moment(this._data.date_modified.toMillis());
    },
    set: function (dateModified) {
      this._data.date_modified = firebase_admin_1.firestore.Timestamp.fromMillis(dateModified.valueOf());
    },
    enumerable: true,
    configurable: true
  });
  PlanLocation.getById = function (id) {
    return new Promise(function (resolve, reject) {
      try {
        PlanLocation.colRef()
          .doc(id)
          .get()
          .then(function (document) {
            if (document.exists) {
              resolve(new PlanLocation(document));
            } else {
              resolve();
            }
          })
          .catch(function (err) {
            reject(err);
          });
      } catch (ex) {
        reject(ex);
      }
    });
  };
  PlanLocation.getPlanLocationById = function (planId) {
    return new Promise(function (resolve, reject) {
      try {
        PlanLocation.colRef()
          .where('plan_id', '==', planId)
          .get()
          .then(function (document) {
            if (document.empty) {
              resolve();
            } else {
              resolve(document.docs.map((d) => new PlanLocation(d)));
            }
          })
          .catch(function (err) {
            reject(err);
          });
      } catch (ex) {
        reject(ex);
      }
    });
  };
  PlanLocation.updateVotes = function (planId, location, userId, voteType) {
    return new Promise(function (resolve, reject) {
      try {
        PlanLocation.colRef()
          .where('plan_id', '==', planId)
          .where('location', '==', location)
          .get()
          .then(function (document) {
            if (document.empty) {
              resolve(true);
            } else {
              var obj = new PlanLocation(document.docs[0]);
              if (voteType === 'up') {
                if (obj.down_votes.includes(userId)) {
                  _.remove(obj.down_votes, function (e) {
                    return e === userId;
                  });
                  obj.save();
                }
                if (!obj.up_votes.includes(userId)) {
                  obj.up_votes.push(userId);
                  obj.save();
                }
              } else if (voteType === 'down') {
                if (obj.up_votes.includes(userId)) {
                  _.remove(obj.up_votes, function (e) {
                    return e === userId;
                  });
                  obj.save();
                }
                if (!obj.down_votes.includes(userId)) {
                  obj.down_votes.push(userId);
                  obj.save();
                }
              }
              resolve(true);
            }
          })
          .catch(function (err) {
            reject(false);
          });
      } catch (ex) {
        reject(false);
      }
    });
  };
  return PlanLocation;
}());
exports.default = PlanLocation;
