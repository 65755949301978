import {LOADING, FETCH_ALL_MESSAGES, ADD_NEW_MESSAGE} from './actionTypes';
import {Chat} from '../lib/model/index';
import moment from 'moment';

export const fetchMessages = (planId) => {
  return async (dispatch) => {
    try {
      dispatch({type: LOADING, payload: true});
      const messages = await Chat.getMessagesByPlanId(planId);
      let msgObj = messages && messages.map((msg) => { return msg.data; });
      msgObj.sort((a, b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0));
      dispatch({type: FETCH_ALL_MESSAGES, payload: msgObj});
      dispatch({type: LOADING, payload: false});
    } catch (error) {
      dispatch({type: FETCH_ALL_MESSAGES, payload: []});
      dispatch({type: LOADING, payload: false});
      console.log('Error!!', error);
    }
  };
};

export const addMessage = (planId, userName, userEmail, message) => {
  return async () => {
    try {
      const newMsg = new Chat('');
      newMsg.plan_id = planId;
      newMsg.planner_name = userName;
      newMsg.planner_email = userEmail;
      newMsg.message = message;
      newMsg.date = moment().format();
      await newMsg.save();
    } catch (error) {
      console.log('Error!!', error);
    }
  };
};

export const addNewMessage = (newObj, id) => {
  newObj = {
    ...newObj,
    id
  };
  return async (dispatch) => {
    try {
      dispatch({
        type: ADD_NEW_MESSAGE,
        payload: newObj
      });
    } catch (error) {
      console.log('Error!!', error);
    }
  };
};
