import {axiosInstance} from '../config/axiosConfig';

export const ForgetPasswordAction = (email) => {
  return async () => {
    try {
      const response = await axiosInstance({
        method: 'post',
        url: '/forgotPassword',
        data: {email},
        validateStatus: () => {
          return true;
        }
      });
      if (response.data === 'recovery email sent') {
        return ({
          showError: false,
          messageFromServer: 'recovery email sent',
          showNullError: false
        });
      } else if (response.status === 500 || response.data === 'No such email exists!') {
        return ({
          showError: true,
          messageFromServer: '',
          showNullError: false
        });
      }
    } catch (error) {
      if (error.response.data) {
        return ({
          showError: true,
          messageFromServer: '',
          showNullError: false
        });
      }
    }
  };
};
