import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import logo from '../../LetsPlanLogo.png';
import './header.css';
import SignIn from '../signin/signin';
import { history } from '../../store/configureStore';

const styles = {
  logo: {
    width: '18%',
    fontSize: '25px',
    textAlign: 'right'
  },
  loginLink: {
    textAlign: 'right',
    width: '80%',
    fontSize: '17px',
    fontWeight: 200
  },
  logIn: {
    color: '#fff'
  }

};

const Header = () => {
  return (
    <div className='header'>
      <div onClick={() => { history.push('/'); }} className="logo"></div>
      <SignIn />
    </div>
  );
};

export default withStyles(styles)(Header);
