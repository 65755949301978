import React, {PureComponent} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Dialog, IconButton, Typography} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import {Close} from '@material-ui/icons';

import {TextBox, Button} from '../../../theme';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Plan} from '../../../lib/model/index';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    backgroundColor: '#2094FA',
    color: '#fff'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0),
    color: theme.palette.grey[500]
  },
  modalTitle: {
    '& h6': {
      fontSize: '20px',
      fontWeight: 600
    }
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const {children, classes, onClose} = props;
  return (
    <MuiDialogTitle disableTypography className={`${classes.modalTitle} ${classes.root}`}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    textAlign: 'center'
  }
}))(MuiDialogContent);

class AddInviteDialog extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      planUrl: '',
      copied: false
    };
  }

  async componentDidMount() {
    const {planId} = this.props;
    const planObj = await Plan.getByPlanId(planId);
    this.setState({planUrl: planObj.plan_url});
  }

  render() {
    const {open, onClose} = this.props;
    const {planUrl, copied} = this.state;
    return (
      <div>
        <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open} maxWidth={'xs'} fullWidth>
          <DialogTitle id="customized-dialog-title" onClose={onClose}>
                        Copy And Share This Link
          </DialogTitle>
          <DialogContent dividers>
            <div className="who-define">
              <h2>Share As You Wish:</h2>
              <TextBox
                value={planUrl}
                onChange={({target: {value}}) => this.setState({value, copied: false})} disabled />
              <CopyToClipboard text={planUrl}
                onCopy={() => this.setState({copied: true})}>
                <div className='btnWrapper'>
                  <Button>Copy and share</Button>
                </div>
              </CopyToClipboard>
              {copied ? <span style={{color: 'red'}}>Copied.</span> : null}
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(AddInviteDialog);
