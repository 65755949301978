import React, { Component } from 'react';
import {
  Typography,
  InputAdornment,
  CircularProgress,
  OutlinedInput,
  withStyles
} from '@material-ui/core';
import { Email, Person, Lock } from '@material-ui/icons';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { history } from '../../store/configureStore';
import SignUpUser from '../../actions/signUpAction';
import { validateEmail, validatePassword, validateName } from '../../utils/validation';
import { Button } from '../../theme';
import { Plan } from '../../lib/model';
import firebase from 'firebase';
import './signup.css';
import { Mixpanel } from '../analytics/Mixpanel';
import { setItem } from '../../utils/localStorageUtils';

const styles = ((theme) => ({
  loader: {
    color: '#fff'
  }
}));

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      errors: {}
    };
  }

  handleChange = ({ target }) => {
    this.setState({
      [target.name]: target.value,
      errors: {}
    });
  };

  checkFieldValidation = () => {
    const { first_name = '', last_name = '', email = '', password = '' } = this.state;
    const firstNameValidation = validateName(first_name);
    const lastNameValidation = validateName(last_name);
    const emailValidation = validateEmail(email);
    const passwordValidation = validatePassword(password);
    if (emailValidation) {
      this.setState((state) => ({
        errors: { ...state.errors, email: { isError: true, errmsg: emailValidation } }
      }));
    }
    if (passwordValidation) {
      this.setState((state) => ({
        errors: { ...state.errors, password: { isError: true, errmsg: passwordValidation } }
      }));
    }
    if (firstNameValidation) {
      this.setState((state) => ({
        errors: { ...state.errors, first_name: { isError: true, errmsg: firstNameValidation } }
      }));
    }
    if (lastNameValidation) {
      this.setState((state) => ({
        errors: { ...state.errors, last_name: { isError: true, errmsg: lastNameValidation } }
      }));
    }
    if (!firstNameValidation && !lastNameValidation && !emailValidation && !passwordValidation) {
      return true;
    } else { return false; }
  }

  handleSignUp = async (e) => {
    const { first_name = '', last_name = '', email = '', password = '' } = this.state;
    const { SignUpUser, plan } = this.props;
    const userData = {
      first_name,
      last_name,
      email,
      password
    };
    try {
      if (this.checkFieldValidation) {
        const res = await SignUpUser(userData);
        if (res.token) {
          await firebase.auth().signInWithCustomToken(res.token);
          Mixpanel.identify(res.userId);
          Mixpanel.track('Successful signup');
          Mixpanel.people.set({
            $userName: res.userName,
            $userEmail: res.userEmail
          });
          setItem('token', res.token);
          const userDetail = {
            userId: res.userId,
            userName: res.userName,
            userEmail: res.userEmail
          };
          setItem('userDetail', JSON.stringify(userDetail));
          if (plan.isInvite) {
            const planObj = await Plan.getByPlanId(plan.planId);
            let participants = planObj.participants;
            let participantsEmail = planObj.participants_email;
            if (participants !== undefined && participantsEmail !== undefined && !participants.includes(userDetail.userName)) {
              participants.push(userDetail.userName);
              participantsEmail.push(userDetail.userEmail);
            } else {
              participants = [userDetail.userName];
              participantsEmail = [userDetail.userEmail];
            }
            planObj.participants = participants;
            planObj.participants_email = participantsEmail;
            await planObj.save();
            history.push(`/plan/${plan.planId}/what`);
          } else {
            history.push('/dashboard');
          }
        } else {
          alert(res);
          this.setState({
            first_name: '',
            last_name: '',
            email: '',
            password: ''
          });
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  render() {
    const { email = '', first_name = '', last_name = '', password = '', errors = {} } = this.state;
    const { classes, loading } = this.props;
    return (
      <div className='background-wrapper'>
        <div className='card-content'>
          <Typography gutterBottom variant="h4" component="h2" className='card-title'>
            Sign Up
          </Typography>
          <Typography gutterBottom variant="h4" component="h6" className='card-text'>It's quick and easy</Typography>
          <div className='mb'>
            <div className='form-control-input'>
              <div className='signin-form'>
                <OutlinedInput
                  id="=first_name"
                  name="first_name"
                  value={first_name}
                  placeholder="First Name"
                  fullWidth={true}
                  onChange={this.handleChange}
                  error={errors.first_name && errors.first_name.isError}
                  startAdornment={
                    <InputAdornment className="icon" position="start">
                      <Person color="primary" />
                    </InputAdornment>
                  }
                />
                <span className='errors'>{errors.first_name && errors.first_name.errmsg}</span>
              </div>
              <div className='signin-form'>
                <OutlinedInput
                  id="last_name"
                  name="last_name"
                  value={last_name}
                  placeholder="Last Name"
                  fullWidth={true}
                  onChange={this.handleChange}
                  error={errors.last_name && errors.last_name.isError}
                  startAdornment={
                    <InputAdornment className="icon" position="start">
                      <Person color="primary" />
                    </InputAdornment>
                  }
                />
                <span className='errors'>{errors.last_name && errors.last_name.errmsg}</span>
              </div>
            </div>
            <div className='signin-form'>
              <OutlinedInput
                name="email"
                value={email}
                placeholder="Email Address"
                fullWidth={true}
                onChange={this.handleChange}
                error={errors.email && errors.email.isError}
                startAdornment={
                  <InputAdornment className="icon" position="start">
                    <Email color="primary" />
                  </InputAdornment>
                }
              />
              <span className='errors'>{errors.email && errors.email.errmsg}</span>
            </div>
            <div className='signin-form'>
              <OutlinedInput
                type="password"
                name="password"
                placeholder="Password"
                fullWidth={true}
                value={password}
                onChange={this.handleChange}
                error={errors.password && errors.password.isError}
                startAdornment={
                  <InputAdornment className="icon" position="start">
                    <Lock color="primary" />
                  </InputAdornment>
                }
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    this.handleSignUp(e);
                  }
                }}
              />
              <span className='errors'>{errors.password && errors.password.errmsg}</span>
            </div>
          </div>
          <div className='footer-bottom'>
            <span>By clicking sign up, you agree to our terms and conditions</span>
            <Button
              variant='contained'
              onClick={this.handleSignUp}
            >
              {loading ? <CircularProgress className={classes.loader} size={25} /> : 'Sign Up'}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading.signUpLoader,
    login_data: state.auth.login_data,
    plan: state.plan
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      SignUpUser
    }, dispatch
  );
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SignUp));
