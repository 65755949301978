import React, {Component} from 'react';
import {Helmet} from 'react-helmet';
import {Typography, Card, withStyles} from '@material-ui/core';
import TextBox from '../../../../theme/textBox';
import {Button} from '../../../../theme';
import AddInviteDialog from '../../Dialogs/addInviteDialog';
import {connect} from 'react-redux';
import {Mixpanel} from '../../../analytics/Mixpanel';
import {Plan} from '../../../../lib/model';
import {history} from '../../../../store/configureStore';
import './planInfo.css';

const styles = ((theme) => ({
  approot: {
    display: 'flex',
    boxShadow:'none'
  },
  multipleIcon: {
    display: 'flex',
    paddingLeft: '20px',
    '& div:nth-of-type(n)': {
      marginLeft: '10px',
      border: '1px solid #333'
    }
  },
  fab: {
    margin: theme.spacing(1)
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  popup: {
    maxWidth: 365,
    '& > div': {
      borderRadius: 10,
      marginTop: -5
    }
  }
}));

class PlanInfo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      planObj: {},
      planTitle: '',
      openAddInviteDialog: false
    };
  }

  async componentDidMount() {
    const {planId} = this.props;
    const planObj = await Plan.getByPlanId(planId);
    this.setState({
      planObj: planObj && planObj.data,
      planTitle: planObj && planObj.data.plan_title
    });
  }

  handleCloseAddInviteDialog = () => {
    this.setState({openAddInviteDialog: false});
  }

  handleAddInviteDialog = () => {
    Mixpanel.track('Share plan');
    this.setState({openAddInviteDialog: true});
  }

  handleChange = ({target}) => {
    this.setState({
      planObj: {
        ...this.state.planObj,
        [target.name]: target.value
      }
    });
  }

  updateData = async () => {
    const {planObj, planTitle} = this.state;
    const {planId} = this.props;
    const obj = await Plan.getByPlanId(planId);
    if (planTitle !== planObj.plan_title) {
      obj.plan_title = planObj.plan_title;
    }
    await obj.save();
  }

  handleMoreDatesClick = () => {
    const {planId} = this.props;
    history.push(`/plan/${planId}/when`);
  }

  handleMorePlacesClick = () => {
    const {planId} = this.props;
    history.push(`/plan/${planId}/where`);
  }

  render() {
    const {classes, planId, handlePlannersClick} = this.props;
    const {openAddInviteDialog, planObj} = this.state;
    return (
      <div>
        <Helmet>
          <title>{planObj && planObj.plan_title}</title>
        </Helmet>
        <Card className={classes.approot}>
          <div className='who'>
            <div className="who-button-add">
              <div className='btnWrapper'>
                <Button onClick={this.handleAddInviteDialog}> Invite More Planners</Button>
                {
                  openAddInviteDialog &&
                  <AddInviteDialog
                    open={openAddInviteDialog}
                    onClose={this.handleCloseAddInviteDialog}
                    planId={planId}
                  />
                }
              </div>
            </div>
            <div>
              <div className="who-define">
                <TextBox
                  id="plan_title"
                  name="plan_title"
                  value={(planObj && planObj.plan_title) || ''}
                  placeholder="Enter Plan Title"
                  fullWidth={true}
                  onChange={this.handleChange}
                  onBlur={this.updateData}
                />
              </div>
              <div className="who-define">
                <h3>Date:</h3>
                <Typography>
                  <span>{planObj.time_period}</span>
                </Typography>
                <span onClick={this.handleMoreDatesClick} className="more-options">More Options</span>
              </div>
              <div className="who-define">
                <h3>Place:</h3>
                <Typography>
                  <span>{planObj.venue}</span>
                </Typography>
                <span onClick={this.handleMorePlacesClick} className="more-options">More Options</span>
              </div>
              <div className="who-define">
                <span onClick={handlePlannersClick}  className="planner-options">{planObj.participants && planObj.participants.length} planners</span>
              </div>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    plan: state.plan
  };
};

export default withStyles(styles)(connect(mapStateToProps, null)(PlanInfo));
