import React from 'react';
import {
  Card
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';

const styles = ((theme) =>({
  root: {
    width: '33%',
    margin: '40px auto',
    boxShadow: '0 0 18px 1px silver',
    [theme.breakpoints.down('sm')]: {
      width: '50%'
    }
  }
}));

const MainCard = (props) => {
  const {classes} = props;
  return (
    <Card
      className={classes.root}
    >
      {props.children}
    </Card>
  );
};

export default withStyles(styles)(MainCard);
