import React, {Component} from 'react';
import {Helmet} from 'react-helmet';
import {Typography, Card, withStyles} from '@material-ui/core';
import TextBox from '../../../../theme/textBox';
import {Button} from '../../../../theme';
import AddInviteDialog from '../../Dialogs/addInviteDialog';
import {connect} from 'react-redux';
import {Mixpanel} from '../../../analytics/Mixpanel';
import {Plan} from '../../../../lib/model';
import {history} from '../../../../store/configureStore';
import './planSummary.css';

const styles = ((theme) => ({
  approot: {
    display: 'flex',
    boxShadow:'none'
  },
  multipleIcon: {
    display: 'flex',
    paddingLeft: '20px',
    '& div:nth-of-type(n)': {
      marginLeft: '10px',
      border: '1px solid #333'
    }
  },
  fab: {
    margin: theme.spacing(1)
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  popup: {
    maxWidth: 365,
    '& > div': {
      borderRadius: 10,
      marginTop: -5
    }
  }
}));

class PlanSummary extends Component {

  constructor(props) {
    super(props);
    this.state = {
      planObj: {},
      planTitle: '',
      openAddInviteDialog: false
    };
  }

  async componentDidMount() {
    const {planId} = this.props;
    const planObj = await Plan.getByPlanId(planId);
    this.setState({
      planObj: planObj && planObj.data,
      planTitle: planObj && planObj.data.plan_title
    });
  }

  handleCloseAddInviteDialog = () => {
    this.setState({openAddInviteDialog: false});
  }

  handleAddInviteDialog = () => {
    Mixpanel.track('Share plan');
    this.setState({openAddInviteDialog: true});
  }

  handleChange = ({target}) => {
    this.setState({
      planObj: {
        ...this.state.planObj,
        [target.name]: target.value
      }
    });
  }

  updateData = async () => {
    const {planObj, planTitle} = this.state;
    const {planId} = this.props;
    const obj = await Plan.getByPlanId(planId);
    if (planTitle !== planObj.plan_title) {
      obj.plan_title = planObj.plan_title;
    }
    await obj.save();
  }

  handleMoreDatesClick = () => {
    const {planId} = this.props;
    history.push(`/plan/${planId}/when`);
  }

  handleMorePlacesClick = () => {
    const {planId} = this.props;
    history.push(`/plan/${planId}/where`);
  }

  render() {
    const {classes, planId, handlePlannersClick} = this.props;
    const {openAddInviteDialog, planObj} = this.state;
    return (
      <div>
        <Helmet>
          <title>{planObj && planObj.plan_title}</title>
        </Helmet>
        <Card className={classes.approot}>
          <div className='who'>
            <div>
              <div className="who-define" marginBottom="-10">
                <strong>
                <TextBox
                  id="plan_title"
                  name="plan_title"
                  value={(planObj && planObj.plan_title) || ''}
                  placeholder="Enter Plan Title"
                  fullWidth={true}
                  onChange={this.handleChange}
                  onBlur={this.updateData}
                />
                </strong>
              </div>
              <div className="who-define">
                <Typography>
                  {planObj.time_period ? <span>{planObj.time_period}</span> : <span>Date: TBD</span>}
                  <span onClick={this.handleMoreDatesClick} className="more-options">Suggest Dates</span>
                </Typography>
                
              </div>
              <div className="who-define">
                <Typography>
                  {planObj.venue ? <span>{planObj.venue}</span> : <span>Place: TBD</span>}
                  <span onClick={this.handleMorePlacesClick} className="more-options">Suggest Locations</span>
                </Typography>
              </div>
              <div className="who-define">
                <Typography>
                  <span onClick={handlePlannersClick}  className="planner-options">{planObj.participants && planObj.participants.length} planner(s)     </span>
                  <span onClick={this.handleAddInviteDialog} className="more-options">Invite More Planners</span>
                  {
                      openAddInviteDialog &&
                      <AddInviteDialog
                        open={openAddInviteDialog}
                        onClose={this.handleCloseAddInviteDialog}
                        planId={planId}
                      />
                    }
                </Typography>
              </div>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    plan: state.plan
  };
};

export default withStyles(styles)(connect(mapStateToProps, null)(PlanSummary));
