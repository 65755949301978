import React from 'react';
import {
  TextField
} from '@material-ui/core';
import clsx from 'clsx';
import {withStyles} from '@material-ui/core/styles';

const styles = ((theme) =>({
  textField: {
    width: '100%',
    fontSize: 16,
    '&:last-child': {
      marginTop: theme.spacing(0)
    },
    '& > div:before': {
      borderColor: '#e0e0e0'
    }
  },
  outlinedTextbox: {
    //width: 200
  },
  textInput: {
    padding: '8.5px'
  }
}));

const TextBox = (props) => {
  const {classes, variant = ''} = props;
  const outlined = variant === 'outlined';
  const className = clsx(
    classes.textField,
    {
      [classes.outlinedTextbox]: outlined
    }
  );
  const inputClassName = clsx(
    {
      [classes.textInput]: outlined
    }
  );
  return (
    <TextField
      className={className}
      error = {props.error}
      fullWidth={props.fullWidth}
      id={props.id}
      InputProps={props.InputProps}
      name={props.name}
      onChange={props.onChange}
      onBlur={props.onBlur}
      onKeyDown={props.onKeyDown}
      placeholder={props.placeholder}
      style={props.style}
      type={props.type}
      value={props.value}
      variant={props.variant}
      disabled={props.disabled}
      inputProps={{
        ...props.inputProps,
        className: inputClassName, 
        
      }}
    />
  );
};

export default withStyles(styles)(TextBox);
