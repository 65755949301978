import React, { Component } from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import firebase from 'firebase';
import ReactGA from 'react-ga';

import Login from './components/login/login';
import { config } from './config/config';
import PublicRoute from './components/routes/publicRoute';
import PrivateRoute from './components/routes/privateRoute';
import YourPlans from './components/yourPlans/yourPlans';
import CreatePlan from './components/createPlan/createPlan';
import ForgotPassword from './components/forgotPassword/forgotPassword';
import ResetPassword from './components/resetPassword/resetPassword';

function initializeReactGA() {
  ReactGA.initialize('UA-61955783-3');
  ReactGA.pageview('/Appjs');
  ReactGA.pageview(window.location.pathname + window.location.search);
}

class App extends Component {

  constructor(props) {
    super(props);
    try {
      if (!firebase.apps.length) {
        firebase.initializeApp(config.firestore);
      }
      if (typeof window === 'object') {
        initializeReactGA();
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  render() {
    return (
      <div className='App'>
        <Switch>
          <PublicRoute exact path='/' component={Login} />
          <PublicRoute exact path='/forgotPassword' component={ForgotPassword} />
          <PublicRoute exact path='/reset/:token' component={ResetPassword} />
          <PrivateRoute exact path='/dashboard' component={YourPlans} />
          <Route exact path='/plan/:id' component={Login} />
          <PrivateRoute exact path='/plan/:id/:comp' component={CreatePlan} />
          <Route exact path="/*" component={() => <div>No page found.</div>} />
        </Switch>
      </div>
    );
  }
}
let exportMod = {}
if (typeof window === 'object') {
  exportMod = withRouter(App);
}
else {
  exportMod = App;
}

export default exportMod
