import {axiosInstance} from '../config/axiosConfig';

export const ResetPasswordAction = (token) => {
  return async () => {
    try {
      const response = await axiosInstance({
        method: 'post',
        url: '/reset',
        data: {token},
        validateStatus: () => {
          return true;
        }
      });
      if (response.status=== 200 && response.data.message === 'password reset link a-ok') {
        return ({
          username: response.data.username,
          updated: false,
          isLoading: false,
          error: false
        });
      } else if (response.status === 500) {
        return ({
          updated: false,
          isLoading: false,
          error: true
        });
      }
    } catch (error) {
      if (error.response.data) {
        return ({
          updated: false,
          isLoading: false,
          error: true
        });
      }
    }
  };
};

export const updatePasswordAction = (userDetails) => {
  return async () => {
    try {
      const response = await axiosInstance({
        method: 'post',
        url: '/updatePassword',
        data: {...userDetails},
        validateStatus: () => {
          return true;
        }
      });
      if (response.status === 200 && response.data === 'password updated') {
        return ({
          updated: true,
          error: false
        });
      } else if (response.status=== 500) {
        return ({
          updated: false,
          error: true
        });
      }
    } catch (error) {
      if (error.response.data) {
        return ({
          updated: false,
          error: true
        });
      }
    }
  };
};
