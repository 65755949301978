import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DateRange from './DateRange';
import {findNextRangeIndex, generateStyles} from '../utils.js';
import classnames from 'classnames';
import coreStyles from '../styles';

class DateRangePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedRange: [findNextRangeIndex(props.ranges), 0]
    };
    this.styles = generateStyles([coreStyles, props.classNames]);
  }
  render() {
    return (
      <div className={classnames(this.styles.dateRangePickerWrapper, this.props.className)}>
        <DateRange
          {...this.props}
          ref={(t) => (this.dateRange = t)}
          className={undefined}
        />
      </div>
    );
  }
}

DateRangePicker.defaultProps = {};

DateRangePicker.propTypes = {
  ...DateRange.propTypes,
  className: PropTypes.string
};

export default DateRangePicker;
