import React, {Component} from 'react';
import moment from 'moment';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import _ from 'lodash';
import {DateRangePicker} from '../../../src';
import Section from './Section';
import 'normalize.css';
import '../styles/global.css';
import '../styles/main.css';
import '../../../src/styles.scss';
import '../../../src/theme/default.scss';
import {addPlanDuration, fetchPlanTimePeriods} from '../../../../../actions/planTimeAction';

class MultiDay extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      count: 0,
      documentIds: [],
      commonCalendarRanges: [],
      myCalendarRanges: [{
        startDate: moment(),
        endDate: moment(),
        key: 'select'
      }],
      dateRangePicker: {}
    };
  }

  async componentDidMount() {
    const {planId, fetchPlanTimePeriods} = this.props;
    await fetchPlanTimePeriods(planId, 'multi');
  }

  componentWillReceiveProps(nextProps) {
    let {documentIds, commonCalendarRanges, myCalendarRanges} = this.state;
    const {auth: {login_data: {userDetail: {userEmail} = {}} = {}} = {}} = this.props;
    if (!_.isEqual(nextProps.plan.multiTimePeriods, commonCalendarRanges)) {
      const multiTimePeriods = nextProps.plan.multiTimePeriods;
      if (!_.isEmpty(multiTimePeriods)) {
        myCalendarRanges = [];
        commonCalendarRanges = [];
        documentIds = [];
        multiTimePeriods && multiTimePeriods.forEach((timePeriod) => {
          const rangeObj = {
            startDate: moment(timePeriod.start_date),
            endDate: moment(timePeriod.end_date),
            key: timePeriod.user_id
          };
          if (_.isEqual(timePeriod.user_id, userEmail)) {
            myCalendarRanges.push(rangeObj);
            documentIds.push(timePeriod.id);
          }
          commonCalendarRanges.push(rangeObj);
        });
        this.setState({documentIds, myCalendarRanges, commonCalendarRanges});
      } else {
        myCalendarRanges = [{
          startDate: moment(),
          endDate: moment(),
          key: 'select'
        }];
        commonCalendarRanges = [];
        this.setState({myCalendarRanges, commonCalendarRanges});
      }
    }
  }

  handleRangeChange = (which, payload) => {
    const {documentIds, myCalendarRanges} = this.state;
    let {count} = this.state;
    if (documentIds.length < 3) {
      count = documentIds.length;
    }
    const {addPlanDuration, planId, auth: {login_data: {userDetail: {userEmail} = {}} = {}} = {}} = this.props;
    const data = payload['select'] !== undefined ?  {...payload.select} : {...payload[userEmail]};
    const endDate = moment(data.endDate);
    const startDate = moment(data.startDate);
    const offset = endDate.diff(startDate);
    if (offset !== 0) {
      if (count >= 3) {
        count =  0;
      }
      let a = myCalendarRanges.slice(); //creates the clone of the array
      a[count] = data;
      this.setState({
        [which]: {...payload},
        myCalendarRanges: a
      }, async () => {
        const {dateRangePicker} = this.state;
        const range = dateRangePicker['select'] !== undefined ?  dateRangePicker.select : dateRangePicker[userEmail];
        try {
          await addPlanDuration(planId, userEmail, range, 'multi', documentIds[count]);
        } catch (ex) {
          console.log('error', ex);
        }
      });
      this.setState({count: count + 1});
    }
  }

  render() {
    const {myCalendarRanges, commonCalendarRanges} = this.state;
    return (
      <main className={'Main'}>
        <Section title="My Calendar">
          <div>
          </div>
          <div>
            {myCalendarRanges && myCalendarRanges.length > 0 && <DateRangePicker
              onChange={this.handleRangeChange.bind(this, 'dateRangePicker')}
              moveRangeOnFirstSelection={true}
              className={'PreviewArea'}
              months={2}
              ranges={myCalendarRanges}
              direction="horizontal"
              minDate={new Date()}
              showPreview={false}
            />}
          </div>
        </Section>
        <Section title="Common Calendar">
          <div>
          </div>
          {commonCalendarRanges && commonCalendarRanges.length > 0 && <DateRangePicker
            ranges={commonCalendarRanges}
            months={2}
            direction='horizontal'
            onChange={()=>{}}
            dragSelectionEnabled={false}
            showPreview={false}
            className={'PreviewArea'}
          />}
        </Section>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    plan: state.plan
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators ({
    addPlanDuration,
    fetchPlanTimePeriods
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiDay);
