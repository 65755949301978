let pattern;
export const validateEmail = (email) => {
  pattern = new RegExp(/([\w.%+-]+)@([\w-]+\.)+([\w]{2,})/);
  if (email === '') {
    return 'Email Required';
  } else if (!pattern.test(email)) {
    return 'Invalid Email';
  } else {
    return;
  }
};

export const validatePassword = (password) => {
  //pattern = new RegExp(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/);
  pattern = new RegExp(/^.*$/);
  if (password === '') {
    return 'Password Required';
  } else if (!pattern.test(password)) {
    return 'Bad Password';
  }
};

export const validateName = (name) => {
  pattern = new RegExp(/^([a-zA-Z])+/);
  if (name === '') {
    return 'FirstName or LastName Required';
  } else if (!pattern.test(name)) {
    return 'Invalid FirstName or LastName';
  }
};

export const validateMobileNumber = (mobile_number) => {
  pattern = new RegExp(/^\d{10}$/);
  if (mobile_number === '') {
    return 'Mobile Number Required';
  } else if (!pattern.test(mobile_number)) {
    return 'Invalid Mobile Number';
  }
};

export const validateDob = (birth_date) => {
  pattern = new RegExp(/^((2000|2400|2800|(19|2[0-9](0[48]|[2468][048]|[13579][26])))-02-29)$/
    |/^(((19|2[0-9])[0-9]{2})-02-(0[1-9]|1[0-9]|2[0-8]))$/|/^(((19|2[0-9])[0-9]{2})-(0[13578]|10|12)-(0[1-9]|[12][0-9]|3[01]))$/
    |/^(((19|2[0-9])[0-9]{2})-(0[469]|11)-(0[1-9]|[12][0-9]|30))$/);
  if (birth_date === '') {
    return 'Date Of Birth Required';
  } else if (!pattern.test(birth_date)) {
    return 'Invalid Date of Birth';
  }
};
